import React, { useState, useEffect } from "react";
import Moment from "moment";

import ApprovedTick from "../assests/ArrovedTick.svg";
import PendingTick from "../assests/Pending.svg";
import LoadingSpinner from "../components/loading-spinner";
import { motion } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon, MinusCircle } from "lucide-react";
import axios from "axios";

const style = {
  tableData: "text-[14px] text-white font-poppins py-4 px-2 min-w-[120px]",
  selectDiv:
    "flex items-center px-2 py-2 border-b border-[#ffffff]/20 text-[14px] text-[#D7BD34] hover:rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10  hover:border-[#818CED]/10 ease-linear duration-150 cursor-pointer",
  selectDiv1:
    "flex items-center px-2 py-2 text-[14px] text-[#E43D3D] border-b border-[#ffffff]/20 hover:rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10   hover:border-[#818CED]/10 ease-linear duration-150 cursor-pointer",
  selectDiv2:
    "flex items-center px-2 py-2 text-[14px] text-[#18b13f] hover:rounded-md mx-3 md-3   hover:bg-[#818CED]/10  hover:border-[#818CED]/10 ease-linear duration-150 cursor-pointer",
};
const TransactionData = ({
  tradeData,
  filterData,
  userDetails,
  getWithdrawalData,
  getDepositedData,
}) => {
  const [showTransactionStatus, setShowTransactionStatus] = useState([]);

  const [withdrawalTransactionStatus, setWithdrawalTransactionStatus] =
    useState([]);

  const [loadingStates, setLoadingStates] = useState(
    Array(tradeData && tradeData.length).fill(false)
  );
  const [loadingStatesWithdrawal, setLoadingStatesWithdrawal] = useState(
    Array(tradeData && tradeData.length).fill(false)
  );

  const toggleTransactionStatus = (index) => {
    const newShowTransactionStatus = [...showTransactionStatus];
    newShowTransactionStatus[index] = !newShowTransactionStatus[index];
    setShowTransactionStatus(newShowTransactionStatus);
  };
  const toggleTransactionWithdrawalStatus = (index) => {
    const newShowTransactionStatus = [...withdrawalTransactionStatus];
    newShowTransactionStatus[index] = !newShowTransactionStatus[index];
    setWithdrawalTransactionStatus(newShowTransactionStatus);
  };

  const handleSubmit = (i, status) => {
    setLoadingStates((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[i] = true;
      return newLoadingStates;
    });
    axios
      .post("https://new-dashboard.server.sypto.xyz/api/admin/deposit-status", {
        username: userDetails && userDetails[4],
        status: status,
        transaction_id: tradeData[i][9],
      })
      .then((res) => {
        setShowTransactionStatus(new Array(tradeData.length).fill(false));
        setLoadingStates((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[i] = false;
          return newLoadingStates;
        });
        getDepositedData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWithdrawalTransaction = (i, status) => {
    setLoadingStatesWithdrawal((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[i] = true;
      return newLoadingStates;
    });
    axios
      .post("https://new-dashboard.server.sypto.xyz/api/admin/withdrawal/withdrawal-status", {
        username: userDetails && userDetails[4],
        status: status,
        transaction_id: tradeData[i][9],
      })
      .then((res) => {
        setLoadingStatesWithdrawal((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[i] = false;
          return newLoadingStates;
        });
        setWithdrawalTransactionStatus(new Array(tradeData.length).fill(false));
        getWithdrawalData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isLoadingTradeData, setIsLoadingTardeData] = useState(true);

  useEffect(() => {
    const newsTimer = setTimeout(() => {
      setIsLoadingTardeData(false);
    }, 3000);

    return () => clearTimeout(newsTimer);
  }, []);
  return (
    <div>
      {!tradeData && !tradeData ? (
        <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)]   custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
          <table className="  text-sm w-full relative  z-0">
            <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
              <tr className="text-white ">
                <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                  DATE
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  CURRENCY
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  NETWORK
                </th>

                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  WALLET ADDRESS
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  AMOUNT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  STATUS
                </th>
              </tr>
            </thead>
          </table>
          <span className="w-full text-white text-2xl md:text-4xl flex flex-row justify-center py-16">
            {isLoadingTradeData === true ? (
              <LoadingSpinner />
            ) : (
              "No Data Available"
            )}
          </span>
        </div>
      ) : tradeData && tradeData.length === 0 ? (
        <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)]   custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
          <table className="  text-sm w-full relative  z-0">
            <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
              <tr className="text-white ">
                <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                  DATE
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  CURRENCY
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  NETWORK
                </th>

                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  WALLET ADDRESS
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  AMOUNT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  STATUS
                </th>
              </tr>
            </thead>
          </table>
          <span className="w-full text-white text-4xl flex flex-row justify-center py-16">
            No Data Available
          </span>
        </div>
      ) : (
        <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)]   custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
          <table className="  text-sm w-full relative  z-0">
            <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
              <tr className="text-[#ffffff]/80 ">
                <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                  DATE
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  CURRENCY
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  NETWORK
                </th>

                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  WALLET ADDRESS
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  AMOUNT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  STATUS
                </th>
              </tr>
            </thead>

            <tbody className="bg-gradient-to-b from-[#202132] via-[#141B22]  to-[#000000]/10 ">
              {tradeData &&
                tradeData.map((dataItem, i) => {
                  return (
                    <tr key={i} className=" z-0 border-b border-[#818CEE]/20  ">
                      <td className={`${style.tableData} pl-4`}>
                        {Moment(String(dataItem[7])).format("DD-MM-YYYY")}
                      </td>
                      <td className={style.tableData}>{dataItem[6]}</td>
                      <td className={style.tableData}>{dataItem[5]}</td>

                      <td className={style.tableData}>{dataItem[4]}</td>
                      <td className={style.tableData}>{dataItem[3]}</td>
                      <td className={style.tableData}>
                        {filterData === "withdrawal" ? (
                          <span>
                            {userDetails && userDetails[5] === "TRUE" ? (
                              <span className="relative">
                                {loadingStatesWithdrawal[i] ? (
                                  <span>
                                    <svg
                                      className="h-6 w-6 text-[#E43D3D] animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  </span>
                                ) : dataItem[8] === "Approved" ? (
                                  <span className="text-[#18b13f] flex flex-row items-center">
                                    {" "}
                                    <img
                                      src={ApprovedTick}
                                      alt="Approved Tick"
                                      className="mr-2"
                                    />
                                    {dataItem[8]}
                                  </span>
                                ) : dataItem[8] === "Declined" ? (
                                  <span className="text-[#E43D3D] flex flex-row items-center">
                                    <MinusCircle className="mr-2 h-5 h-5" />
                                    {dataItem[8]}
                                  </span>
                                ) : (
                                  <span>
                                    <span
                                      onClick={() =>
                                        toggleTransactionWithdrawalStatus(i)
                                      }
                                      className={`flex text-[#D7BD34] pb-2 cursor-pointer`}
                                    >
                                      <span className="flex ">
                                        {" "}
                                        <img
                                          src={PendingTick}
                                          alt="Pending Tick"
                                          className="mr-2"
                                        />{" "}
                                        {dataItem[8]}
                                      </span>

                                      <span
                                        className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                                      >
                                        {withdrawalTransactionStatus[i] ? (
                                          <ChevronUpIcon className="w-5 h-5 text-white/50" />
                                        ) : (
                                          <ChevronDownIcon className="w-5 h-5 text-white/50" />
                                        )}
                                      </span>
                                    </span>
                                    {withdrawalTransactionStatus[i] && (
                                      <motion.span
                                        initial={{ y: -50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{
                                          duration: 0.5,
                                          type: "spring",
                                        }}
                                        exit={{ y: -50, opacity: 0 }}
                                        className={`absolute right-0 top-8 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                                      >
                                        <span
                                          className={`${style.selectDiv} pl-3 font-medium`}
                                          onClick={() =>
                                            handleWithdrawalTransaction(
                                              i,
                                              "Pending"
                                            )
                                          }
                                        >
                                          <img
                                            src={PendingTick}
                                            alt="Pending Tick"
                                            className="mr-2"
                                          />
                                          Pending
                                        </span>
                                        <span
                                          className={`${style.selectDiv1} pl-3 font-medium`}
                                          onClick={() =>
                                            handleWithdrawalTransaction(
                                              i,
                                              "Declined"
                                            )
                                          }
                                        >
                                          <MinusCircle className="mr-2 w-4 h-4" />
                                          Declined
                                        </span>
                                        <span
                                          className={`${style.selectDiv2} pl-3 font-medium`}
                                          onClick={() =>
                                            handleWithdrawalTransaction(
                                              i,
                                              "Approved"
                                            )
                                          }
                                        >
                                          <img
                                            src={ApprovedTick}
                                            alt="Approved Tick"
                                            className="mr-2"
                                          />
                                          Approved
                                        </span>
                                      </motion.span>
                                    )}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span>
                                {dataItem[8] === "Approved" ? (
                                  <span className="text-[#18b13f] flex flex-row items-center">
                                    {" "}
                                    <img
                                      src={ApprovedTick}
                                      alt="Approved Tick"
                                      className="mr-2"
                                    />
                                    {dataItem[8]}
                                  </span>
                                ) : dataItem[8] === "Pending" ? (
                                  <span className="text-[#D7BD34] flex flex-row items-center">
                                    {" "}
                                    <img
                                      src={PendingTick}
                                      alt="Pending Tick"
                                      className="mr-2"
                                    />
                                    {dataItem[8]}
                                  </span>
                                ) : (
                                  <span className="text-[#E43D3D] flex flex-row items-center">
                                    {" "}
                                    <MinusCircle className="mr-2 h-5 h-5" />
                                    {dataItem[8]}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        ) : (
                          <span>
                            {userDetails && userDetails[5] === "TRUE" ? (
                              <span className="relative">
                                {loadingStates[i] ? (
                                  <span>
                                    <svg
                                      className="h-6 w-6 text-[#E43D3D] animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  </span>
                                ) : dataItem[8] === "Approved" ? (
                                  <span className="text-[#18b13f] flex flex-row items-center">
                                    {" "}
                                    <img
                                      src={ApprovedTick}
                                      alt="Approved Tick"
                                      className="mr-2"
                                    />
                                    {dataItem[8]}
                                  </span>
                                ) : dataItem[8] === "Declined" ? (
                                  <span className="text-[#E43D3D] flex flex-row items-center">
                                    <MinusCircle className="mr-2 h-5 h-5" />
                                    {dataItem[8]}
                                  </span>
                                ) : (
                                  <span>
                                    <span
                                      onClick={() => toggleTransactionStatus(i)}
                                      className={`flex text-[#D7BD34] pb-2 cursor-pointer`}
                                    >
                                      <span className="flex ">
                                        {" "}
                                        <img
                                          src={PendingTick}
                                          alt="Pending Tick"
                                          className="mr-2"
                                        />{" "}
                                        {dataItem[8]}
                                      </span>

                                      <span
                                        className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                                      >
                                        {showTransactionStatus[i] ? (
                                          <ChevronUpIcon className="w-5 h-5 text-white/50" />
                                        ) : (
                                          <ChevronDownIcon className="w-5 h-5 text-white/50" />
                                        )}
                                      </span>
                                    </span>
                                    {showTransactionStatus[i] && (
                                      <motion.span
                                        initial={{ y: -50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{
                                          duration: 0.5,
                                          type: "spring",
                                        }}
                                        exit={{ y: -50, opacity: 0 }}
                                        className={`absolute right-0 top-8 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                                      >
                                        <span
                                          className={`${style.selectDiv} pl-3 font-medium`}
                                          onClick={() =>
                                            handleSubmit(i, "Pending")
                                          }
                                        >
                                          <img
                                            src={PendingTick}
                                            alt="Pending Tick"
                                            className="mr-2"
                                          />
                                          Pending
                                        </span>
                                        <span
                                          className={`${style.selectDiv1} pl-3 font-medium`}
                                          onClick={() =>
                                            handleSubmit(i, "Declined")
                                          }
                                        >
                                          <MinusCircle className="mr-2 w-4 h-4" />
                                          Declined
                                        </span>
                                        <span
                                          className={`${style.selectDiv2} pl-3 font-medium`}
                                          onClick={() =>
                                            handleSubmit(i, "Approved")
                                          }
                                        >
                                          <img
                                            src={ApprovedTick}
                                            alt="Approved Tick"
                                            className="mr-2"
                                          />
                                          Approved
                                        </span>
                                      </motion.span>
                                    )}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span>
                                {dataItem[8] === "Approved" ? (
                                  <span className="text-[#18b13f] flex flex-row items-center">
                                    {" "}
                                    <img
                                      src={ApprovedTick}
                                      alt="Approved Tick"
                                      className="mr-2"
                                    />
                                    {dataItem[8]}
                                  </span>
                                ) : dataItem[8] === "Pending" ? (
                                  <span className="text-[#D7BD34] flex flex-row items-center">
                                    {" "}
                                    <img
                                      src={PendingTick}
                                      alt="Pending Tick"
                                      className="mr-2"
                                    />
                                    {dataItem[8]}
                                  </span>
                                ) : (
                                  <span className="text-[#E43D3D] flex flex-row items-center">
                                    {" "}
                                    <MinusCircle className="mr-2 h-5 h-5" />
                                    {dataItem[8]}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TransactionData;
