import React, { useState, useEffect } from "react";
import WithdrawalData from "../UserDetails/WithdrawalData";
import TransactionData from "./TransactionData";
import Moment from "moment";
import DepositData from "../UserDetails/DepositData";
import axios from "axios";

const TransferMain = () => {
  const [deposit, setDeposit] = useState();
  const [withdrawal, setWithdrawal] = useState();

  const getDepositedData = () => {
    axios
      .get(
        "https://new-dashboard.server.sypto.xyz/api/user/deposit/dashboard/history"
      )
      .then((res) => {
        setDeposit(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWithdrawalData = () => {
    axios
      .get(
        "https://new-dashboard.server.sypto.xyz/api/user/withdrawal/dashboard/history"
      )
      .then((res) => {
        setWithdrawal(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDepositedData();
    getWithdrawalData();
  }, []);

  const depositData = deposit && deposit.data.values;

  const withdrawalData = withdrawal && withdrawal.data.values;
  const [filterData, setFilterData] = useState("withdrawal");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const filteredTradesFromDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[7]);
      const start = Moment(String(item)).format("YYYY-MM-DD") >= startDate;
      return start;
    });
    const sortedTradeHistory =
      value && value.slice().sort((a, b) => new Date(b[7]) - new Date(a[7]));
    return sortedTradeHistory;
  };

  const filteredTradesFromEndDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[7]);
      const end = Moment(String(item)).format("YYYY-MM-DD") <= endDate;
      return end;
    });
    const sortedTradeHistory =
      value && value.slice().sort((a, b) => new Date(b[7]) - new Date(a[7]));
    return sortedTradeHistory;
  };

  const filteredTradesFromStartToEndDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[7]);
      const start = Moment(String(item)).format("YYYY-MM-DD") >= startDate;
      const end = Moment(String(item)).format("YYYY-MM-DD") <= endDate;
      const data = start && end;
      return data;
    });
    const sortedTradeHistory =
      value && value.slice().sort((a, b) => new Date(b[7]) - new Date(a[7]));
    return sortedTradeHistory;
  };

  const filterAndSorted = (rawData) => {
    var raw = rawData;
    // console.log("raq", raw[0][7]);
    const sortedTradeHistory =
      raw && raw.slice().sort((a, b) => new Date(b[7]) - new Date(a[7]));
    var sortedData = [];
    if (startDate) {
      sortedData = filteredTradesFromDate(raw);
    }
    if (endDate) {
      sortedData = filteredTradesFromEndDate(raw);
    }
    if (startDate && endDate) {
      sortedData = filteredTradesFromStartToEndDate(raw);
    }
    sortedData =
      startDate && endDate
        ? sortedData
        : sortedData.length === 0
        ? sortedTradeHistory
        : sortedData;

    return sortedData;
  };
  return (
    <div className="mt-20 px-4 md:px-12">
      <div className="relative w-full pt-10 pb-2 md:pt-12 md:pb-4 lg:pt-12 lg:pb-8 z-10">
        {/* 
      <div className="absolute -top-[560px] -left-[180px] z-0  blur-box w-[460px] h-[560px] md:w-[760px] md:h-[760px] rounded-full  filter blur-2xl opacity-90 "></div>
        */}

        <div className="flex flex-row items-center ">
          <div
            onClick={() => setFilterData("withdrawal")}
            className={
              filterData === "withdrawal"
                ? "text-white text-2xl font-semibold font-poppins cursor-pointer "
                : "text-[#ffffff]/20 text-2xl font-semibold font-poppins cursor-pointer"
            }
          >
            Withdrawal
          </div>
          <div className="px-6 text-[#ffffff]/30">|</div>
          <div
            onClick={() => setFilterData("deposit")}
            className={
              filterData === "deposit"
                ? "text-white text-2xl font-semibold font-poppins cursor-pointer "
                : "text-[#ffffff]/20 text-2xl font-semibold font-poppins cursor-pointer"
            }
          >
            Deposit
          </div>
        </div>
        <div className="mt-2 text-xs md:text-sm text-[#ffffff]/50 font-light font-poppins">
          Whitelist your Wallet Address for Quick Withdrawals from your
          Dashboard.
        </div>
        <div>
          {filterData === "withdrawal" ? (
            <WithdrawalData getWithdrawalData={getWithdrawalData} />
          ) : (
            <DepositData getDepositedData={getDepositedData} />
          )}
        </div>
        <div className="mt-6 flex  flex-row justify-between items-center text-center">
          <div
            className={`text-[16px] md:text-[20px] lg:text-[22px] text-white font-semibold font-montserrat`}
          >
            Transaction History
          </div>
        </div>
        {filterData === "withdrawal" ? (
          <TransactionData
            tradeData={filterAndSorted(withdrawalData && withdrawalData)}
            userDetails={userDetails && userDetails}
            filterData={filterData}
            getWithdrawalData={getWithdrawalData}
          />
        ) : (
          <TransactionData
            tradeData={filterAndSorted(depositData && depositData)}
            userDetails={userDetails && userDetails}
            filterData={filterData}
            getDepositedData={getDepositedData}
          />
        )}
      </div>
    </div>
  );
};

export default TransferMain;
