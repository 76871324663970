import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Login from "./Login/login";
import ForgotPassowrd from "./Login/forgotPassword";
import ResetPassowrd from "./Login/ResetPassword";
import Dashboard from "./Home/DashBoard";
import ExchangeIntegrate from "./Home/ExchnageIntegrate";
import { connect, useDispatch } from "react-redux";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import NewsFeed from "./NewsFeed/NewsFeed";
// import AdminLayout from "./Admin/adminLayout";
import TradeHistoryMain from "./TradeHistory/TradeHistoryMain";
import TransferLayout from "./Transfer/TransferLayout";
import AdminLayout from "./Admin/adminLayout";

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  return (
    <div className="App font-poppins">
      <Switch>
        <Route
          exact
          path="/"
          // component={Dashboard}
          component={() => (props.isAuthenticated ? <Dashboard /> : <Login />)}
        />

        <Route
          path="/transfer"
          component={() =>
            props.isAuthenticated ? <TransferLayout /> : <Login />
          }
        />
        <Route
          path="/integrate-exchnage"
          component={() =>
            props.isAuthenticated ? <ExchangeIntegrate /> : <Login />
          }
        />

        <Route
          path="/trade-history"
          component={() =>
            props.isAuthenticated ? <TradeHistoryMain /> : <Login />
          }
        />
        <Route
          path="/news-feed"
          component={() => (props.isAuthenticated ? <NewsFeed /> : <Login />)}
        />
        <Route
          path="/admin"
          component={() =>
            props.isAuthenticated ? <AdminLayout /> : <Login />
          }
        />
        <Route exact path="/forgot-password" component={ForgotPassowrd} />
        <Route exact path="/reset-password" component={ResetPassowrd} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);
