import React, { useState } from "react";
import styles from "../Home/Dashboard.module.css";
import { UseTradeHistoryData } from "../Utils/TradeData";
import DashboardData from "./DashBoardData";
import TradeData from "./TradeData";
import Moment from "moment";
import { motion } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { LuFilter } from "react-icons/lu";
import LoadingSpinner from "../components/loading-spinner";

const style = {
  InputBox: "rounded-lg box-border border-[1px] border-solid border-[#d3d3ce] ",

  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10 hover:text-white/85   ease-linear duration-150 cursor-pointer",
};
const TradeHistory = () => {
  const { isLoading, data: tradeHistory } = UseTradeHistoryData();

  const tradeData = tradeHistory && tradeHistory.data.values;

  const [filterData, setFilterData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const filteredProfitTrades = (Data) => {
    const value = Data.filter((result) => {
      const item = result[8];
      return item > 1;
    });
    return value;
  };

  const filteredLossTrades = (Data) => {
    const value = Data.filter((result) => {
      const item = result[8];
      return item < 1;
    });
    return value;
  };

  const filteredTradesFromDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[0]);
      const start = Moment(String(item)).format("YYYY-MM-DD") >= startDate;
      return start;
    });
    return value;
  };

  const filteredTradesFromEndDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[0]);
      const end = Moment(String(item)).format("YYYY-MM-DD") <= endDate;
      return end;
    });
    return value;
  };

  const filteredTradesFromStartToEndDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[0]);
      const start = Moment(String(item)).format("YYYY-MM-DD") >= startDate;
      const end = Moment(String(item)).format("YYYY-MM-DD") <= endDate;
      const data = start && end;
      return data;
    });
    return value;
  };

  const filterAndSorted = (rawData) => {
    var raw = rawData;
    const sortedTradeHistory =
      raw && raw.slice().sort((a, b) => new Date(b[0]) - new Date(a[0]));
    var sData = [];
    var mData = [];
    if (filterData === "profit") {
      sData = filteredProfitTrades(raw);
    }
    if (filterData === "loss") {
      mData = filteredLossTrades(raw);
    }
    var newData = [...sData];
    var lossData = [...mData];
    newData =
      newData.length !== 0
        ? newData
        : lossData.length !== 0
        ? lossData
        : sortedTradeHistory;
    var sortedData = [];
    if (startDate) {
      sortedData = filteredTradesFromDate(newData);
    }
    if (endDate) {
      sortedData = filteredTradesFromEndDate(newData);
    }
    if (startDate && endDate) {
      sortedData = filteredTradesFromStartToEndDate(newData);
    }
    sortedData = sortedData.length === 0 ? newData : sortedData;
    return sortedData;
  };

  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  return (
    <div className="mt-20 md:px-12">
      {isLoading === true ? (
        <div className="relative w-full pt-10 pb-2 md:pt-12 md:pb-4 lg:pt-16 lg:pb-8">
          <div className="absolute -top-[600px] -left-[200px] z-0  blur-box w-[460px] h-[560px] md:w-[760px] md:h-[760px] rounded-full  filter blur-2xl opacity-90 "></div>

          <div className="flex flex-row justify-center text-center py-36 h-[100vh]">
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div className="relative w-full pt-10 pb-2 md:pt-12 md:pb-4 lg:pt-16 lg:pb-8">
          <div className="absolute -top-[600px] -left-[200px] z-0  blur-box w-[460px] h-[560px] md:w-[760px] md:h-[760px] rounded-full  filter blur-2xl opacity-90 "></div>
          <DashboardData />

          <div className="px-4 md:px-6 ">
            <div className="mt-6 flex md:px-3 lg:pl-8 lg:pr-12 flex-row justify-between items-center text-center">
              <div
                className={`text-[16px] md:text-[20px] lg:text-[22px] text-white font-semibold font-montserrat`}
              >
                Trade History
              </div>
              <div
                className="bg-[#E43D3D] p-2 lg:p-3 rounded-full cursor-pointer "
                onClick={() => setShowFilter(true)}
              >
                <LuFilter color="#ffffff" className="w-4 h-4 lg:w-5 lg:h-5" />
              </div>
            </div>
            {showFilter === true ? (
              <div className="flex flex-col w-full space-y-4 md:space-y-0 md:justify-between md:pt-6 md:flex-row py-2 md:px-3 lg:pl-8 lg:pr-12 ">
                <div className={`lg:w-full relative`}>
                  <div
                    onClick={() => setShow(!show)}
                    className={`${styles.dropdownBox} md:[180px] lg:w-[210px] flex `}
                  >
                    {filterData === "profit" ? (
                      <div className={`${styles.dropDownSelection} text-white`}>
                        Profit Trades
                      </div>
                    ) : filterData === "loss" ? (
                      <div className={`${styles.dropDownSelection} text-white`}>
                        Loss Trades
                      </div>
                    ) : filterData === "view-all" ? (
                      <div className={`${styles.dropDownSelection} text-white`}>
                        View All Trades
                      </div>
                    ) : (
                      <div
                        className={`${styles.dropDownSelection} text-white/50`}
                      >
                        View All Trades
                      </div>
                    )}

                    <div
                      className={`md:pl-2 lg:pl-6 ease-linear duration-150 text-white cursor-pointer`}
                    >
                      {show ? (
                        <ChevronUpIcon className="w-5 h-5 text-white/50" />
                      ) : (
                        <ChevronDownIcon className="w-5 h-5 text-white/50" />
                      )}
                    </div>
                  </div>
                  {show && (
                    <motion.div
                      initial={{ y: -50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.5, type: "spring" }}
                      exit={{ y: -50, opacity: 0 }}
                      className={`absolute right-0 top-13 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 mt-2 `}
                    >
                      <div
                        className={`${style.selectDiv} pl-3 font-medium`}
                        onClick={() => {
                          setFilterData("view-all");
                          setShow(!show);
                        }}
                      >
                        View All Trades{" "}
                      </div>
                      <div
                        className={`${style.selectDiv} pl-3 font-medium`}
                        onClick={() => {
                          setFilterData("profit");
                          setStartDate("");
                          setEndDate("");
                          setShow(!show);
                        }}
                      >
                        Profit Trades
                      </div>
                      <div
                        className={`${style.selectDiv} pl-3 font-medium`}
                        onClick={() => {
                          setFilterData("loss");
                          setStartDate("");
                          setEndDate("");
                          setShow(!show);
                        }}
                      >
                        Loss Trades
                      </div>
                    </motion.div>
                  )}
                </div>
                <div
                  className={`lg:w-full md:flex md:space-x-3 md:items-center `}
                >
                  <label className="text-[#ffffff]/50 ">From :</label>
                  <input
                    key="min"
                    type="date"
                    className={`${styles.inputDate} w-full md:w-[150px] lg:w-[190px] lg:py-2 lg:px-4`}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div
                  className={`lg:w-full md:flex md:space-x-3 md:items-center `}
                >
                  <label className="text-[#ffffff]/50  mr-2">To : </label>
                  <input
                    key="max"
                    type="date"
                    className={`${styles.inputDate} w-full md:w-[150px] lg:w-[190px] lg:py-2 lg:px-4`}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className={`flex items-center `}>
                  <button
                    className="w-full md:w-[100px] lg:w-[140px] text-center text-[#ffffff]/50 border-[1px] border-[#ffffff]/10 px-3 py-2 lg:py-3 lg:px-6 rounded-[24px] font-medium md:text-[14px] lg:text-[16px]"
                    onClick={() => {
                      setStartDate("");
                      setEndDate("");
                      setShowFilter(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <TradeData tradeDataAll={filterAndSorted(tradeData && tradeData)} />
        </div>
      )}
    </div>
  );
};

export default TradeHistory;
