import React from "react";
//Firebase
import { motion } from "framer-motion";
import { Link, withRouter, useHistory } from "react-router-dom";
import { logout } from "../store/actions/AuthActions";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineWallet,
  AiOutlineLogout,
} from "react-icons/ai";
import styles from "../Home/Dashboard.module.css";

const MobileNav = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
    history.push("/");
  };

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  // var intials;
  // var fullName = userDetails && userDetails[4];
  // intials =
  //   fullName &&
  //   fullName
  //     .split(" ")
  //     .map((name) => name[0])
  //     .join("")
  //     .toUpperCase();

  return (
    <motion.div
      initial={{ y: -1000 }}
      animate={{ y: 0 }}
      exit={{ y: -1000 }}
      transition={{ duration: 1 }}
      className="flex flex-col space-y-0 w-full h-screen  bg-[#14141D] text-neutral-50 mt-[40px] pt-10 fixed top-0 z-40 font-Poppins "
    >
      <Link to="/">
        <div
          className={
            window.location.pathname === "/"
              ? "bg-gradient-to-r from-[#332742] to-[#1a1a23] "
              : "border-b border-gray-800"
          }
        >
          <div className="w-full flex space-x-2 items-center text-xl px-6 py-4 cursor-pointer mx-auto">
            <AiOutlineHome
              className={
                window.location.pathname === "/"
                  ? styles.usericonsActive
                  : styles.usericons
              }
            />
            <span className="flex  font-semibold text-[16px] text-[#FFFFFFCC]">
              Home
            </span>
          </div>
        </div>
      </Link>
      <Link to="/trade-history">
        <div
          className={
            window.location.pathname === "/user-details"
              ? "bg-gradient-to-r from-[#332742] to-[#1a1a23] "
              : "border-b border-gray-800"
          }
        >
          <div className="w-full flex space-x-2 items-center text-xl px-6 py-4 cursor-pointer mx-auto">
            <AiOutlineUser
              className={
                window.location.pathname === "/trade-history"
                  ? styles.usericonsActive
                  : styles.usericons
              }
            />
            <span className="flex  font-semibold text-[16px] text-[#FFFFFFCC]">
              Trade History
            </span>
          </div>
        </div>
      </Link>
      <Link to="/transfer">
        <div
          className={
            window.location.pathname === "/transfer"
              ? "bg-gradient-to-r from-[#332742] to-[#1a1a23] "
              : "border-b border-gray-800"
          }
        >
          <div className="w-full flex space-x-2 items-center text-xl px-6 py-4 cursor-pointer mx-auto">
            <AiOutlineWallet
              className={
                window.location.pathname === "/transfer"
                  ? styles.usericonsActive
                  : styles.usericons
              }
            />
            <span className="flex  font-semibold text-[16px] text-[#FFFFFFCC]">
              Transfer
            </span>
          </div>
        </div>
      </Link>

      {userDetails && userDetails[5] === "TRUE" ? (
        <Link to="/admin">
          <div
            className={
              window.location.pathname === "/admin"
                ? "bg-gradient-to-r from-[#332742] to-[#1a1a23] "
                : "border-b border-gray-800"
            }
          >
            <div className="w-full flex space-x-2 items-center text-xl px-6 py-4 cursor-pointer mx-auto">
              <AiOutlineUser
                className={
                  window.location.pathname === "/admin"
                    ? styles.usericonsActive
                    : styles.usericons
                }
              />
              <span className="flex  font-semibold text-[16px] text-[#FFFFFFCC]">
                Admin
              </span>
            </div>
          </div>
        </Link>
      ) : null}
      {/*-------------------------Logout-------------------- */}

      <div
        onClick={onLogout}
        className="flex space-x-2 w-full px-6 py-4  cursor-pointer mx-auto"
      >
        <AiOutlineLogout size={20} className="text-[#FFFFFFCC]" />
        <span className="flex  font-normal text-[16px] text-[#FFFFFFCC]">
          Logout
        </span>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(MobileNav));
