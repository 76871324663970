import React, { useState, useEffect } from "react";
import { Wallet } from "lucide-react";
import { motion } from "framer-motion";
import { ArrowLeftRight, ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import moment from "moment";

import NewsFeed from "../assests/NewsFeed.svg";
import QuickTransfer from "./QuickTransfer";

import {
  UseChartTistData,
  UseLiveTradeData,
  UseNewsFeedData,
  UseDashboarddata,
  UseEconomicCalenderData,
  UseAssetsData,
} from "../Utils/AllData";

import ChartPlotting from "../Utils/chart";
import LiveTrade from "./LiveTrade";
import QuickDeposit from "./QuickDeposit";
import LoadingSpinner from "../components/loading-spinner";

const style = {
  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10 hover:text-white/85  ease-linear duration-150 cursor-pointer",
};
const HomePage = () => {
  const [selectTransfer, setSelectTransfer] = useState("Withdrawal");
  const [showSelectTransfer, setShowSelectTransfer] = useState(false);
  const { data: chartData } = UseChartTistData();
  const { data: liveTrade } = UseLiveTradeData();
  const { data: newsFeed } = UseNewsFeedData();
  const { data: economicCalender } = UseEconomicCalenderData();
  const { data: assetsData } = UseAssetsData();
  const { data } = UseDashboarddata();

  // baclance
  const dashboardData = data && data.data.values;

  // news feed Data
  const newsFeedData = newsFeed && newsFeed.data.items;
  // economic calender
  const economicCalenderData = economicCalender && economicCalender.data.items;

  //live trade data
  const liveTradeData = liveTrade && liveTrade.data;
  //assets data
  const holdingData = assetsData && assetsData.data;

  //chart data
  const plotChartData = chartData && chartData.data;
  const performceAccountValue =
    plotChartData && plotChartData.map((item) => [item[0], item[1]]);
  const performceAccountValueData =
    performceAccountValue &&
    performceAccountValue.map((innerArray, index) =>
      innerArray.map((value, columnIndex) => {
        // Skip the first column
        if (columnIndex === 0 || typeof value !== "string") {
          return value;
        }

        // Remove the leading rupee sign and commas, then convert to a number
        return parseFloat(value.replace(/^₹|,/g, ""));
      })
    );

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCalender, setIsLaadingCalender] = useState(true);
  const [isLoadingNews, setIsLoadingNews] = useState(true);

  useEffect(() => {
    // Set up a timer for fetching assetsData with a 5-second delay
    const timer = setTimeout(() => {
      setIsLoading(false); // Update loading state after the timeout
    }, 3000); // Adjust the timeout duration as needed

    // Cleanup function to clear the timer if the component unmounts or if data is fetched
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const newsTimer = setTimeout(() => {
      setIsLoadingNews(false);
    }, 3000);

    return () => clearTimeout(newsTimer);
  }, []);

  useEffect(() => {
    const calenderTimer = setTimeout(() => {
      setIsLaadingCalender(false);
    }, 3000);
    // Cleanup function to clear the timer if the component unmounts or if data is fetched

    return () => clearTimeout(calenderTimer);
  }, []);

  return (
    <div className="flex pt-16 md:pt-12 px-3 md:px-12">
      <div className="relative w-full  pt-10 pb-2 md:pt-12 md:pb-4 lg:pt-16 lg:pb-8">
        <div className="absolute -top-[450px] -left-[200px] z-0  blur-box w-[460px] h-[560px] md:w-[760px] md:h-[760px] rounded-full  filter blur-2xl opacity-90 "></div>

        <div className="flex flex-col md:flex-row h-full gap-4">
          <div className="w-full md:w-[30%] z-10">
            {/* Wallet Balance  */}
            <div className="w-full bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
              <div
                className={`flex flex-col justify-center h-full bg-gradient-to-b from-[#000000]/70 from-80% via-[#29293e] via-80% to-[#29293e] to-20% rounded-[12px] md:rounded-[26px] text-white`}
              >
                <div className="space-y-1 md:space-y-3 px-6 py-3 md:px-10 md:py-4">
                  <div
                    className={`flex flex-row items-center leading-5 text-lg text-[#ffffff] font-normal md:text-lg lg:text-lg`}
                  >
                    <Wallet
                      color="#ffffff"
                      className="w-4 h-4 lg:w-5 lg:h-5 mr-2"
                    />
                    AUM{" "}
                    {dashboardData &&
                      dashboardData.map((ele, i) => {
                        return (
                          <span
                            key={i}
                            className="ml-4 bg-[#51DB74]/10 px-3 text-[12px] font-bold rounded-[4px] font-poppins"
                          >
                            {parseFloat(ele[6]) === 0 ? (
                              <span className="text-[#ffffff] ">{ele[6]}%</span>
                            ) : parseFloat(ele[6]) > 0 ? (
                              <span className="text-[#51DB74] ">
                                +{ele[6]}%
                              </span>
                            ) : (
                              <span className="text-[#E43D3D] ">{ele[6]}%</span>
                            )}
                          </span>
                        );
                      })}
                  </div>
                  {dashboardData &&
                    dashboardData.map((ele, i) => {
                      return (
                        <div
                          className={`leading-5 text-md font-semibold text-white md:font-semibold lg:text-[30px] pt-2`}
                          key={i}
                        >
                          {parseFloat(ele[2]) === 0 ? (
                            <span>{ele[2]}$</span>
                          ) : parseFloat(ele[2]) > 0 ? (
                            <span>{ele[2]}$</span>
                          ) : (
                            <span>-{Math.abs(ele[2])}$</span>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className="bg-gradient-to-b from-[#000000]/10 to-[#000000]/50 pt-4 rounded-b-[12px] md:rounded-b-[26px] space-y-1 md:space-y-3 px-6 py-3 md:px-10 md:py-4">
                  <div className="text-[#ffffff]/50 text-lg">Profit Today</div>
                  {dashboardData &&
                    dashboardData.map((ele, i) => {
                      return (
                        <div
                          className="leading-5 text-md font-semibold  md:font-semibold lg:text-[30px] md:pt-2"
                          key={i}
                        >
                          {parseFloat(ele[3]) === 0 ? (
                            <span className="text-[#ffffff]">{ele[3]}$</span>
                          ) : parseFloat(ele[3]) > 0 ? (
                            <span className="text-[#3AC985]">+{ele[3]}$</span>
                          ) : (
                            <span className="text-[#E43D3D]">
                              -{Math.abs(ele[3])}$
                            </span>
                          )}
                          {parseFloat(ele[5]) > 0 ? (
                            <span className="text-[#3AC985] text-xs md:text-lg font-bold leading-4 ml-2  mt-1 md:mt-2">
                              (+{ele[5]}%)
                            </span>
                          ) : parseFloat(ele[5]) === 0 ? (
                            <span className="text-[#ffffff] text-xs md:text-lg font-bold leading-4 ml-2  mt-1 md:mt-2">
                              ({ele[5]}%)
                            </span>
                          ) : (
                            <span className="text-[#E43D3D] text-xs md:text-lg font-bold leading-4 ml-2  mt-1 md:mt-2">
                              ({ele[5]}%)
                            </span>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* Quick Transfer  */}
            <div className=" z-10 w-full mt-4 bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
              <div
                className={`flex flex-col justify-center h-full bg-[#14141D]/80   rounded-[12px] md:rounded-[26px] text-white`}
              >
                <div className="space-y-1 md:space-y-3 px-2 py-3 md:px-6 md:py-4">
                  <div className="text-white text-lg flex flex-row items-center">
                    <ArrowLeftRight size={18} />{" "}
                    <span className="text-lg ml-3">Quick Transfer</span>
                  </div>
                  <div className="relative">
                    <div
                      className="py-2 px-4 flex flex-row items-center justify-between border border-[#ffffff]/20 rounded-[8px]  cursor-pointer"
                      onClick={() => setShowSelectTransfer(!showSelectTransfer)}
                    >
                      <div className="capitalize text-lg text-white font-semibold font-poppins">
                        {" "}
                        {selectTransfer}
                      </div>
                      <div
                        className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                      >
                        {showSelectTransfer ? (
                          <ChevronUpIcon className="w-5 h-5 text-white/50" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5 text-white/50" />
                        )}
                      </div>
                    </div>
                    {showSelectTransfer && (
                      <motion.div
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, type: "spring" }}
                        exit={{ y: -50, opacity: 0 }}
                        className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                      >
                        <div
                          className={`${style.selectDiv} pl-3 font-medium`}
                          onClick={() => {
                            setSelectTransfer("Withdrawal");
                            setShowSelectTransfer(!showSelectTransfer);
                          }}
                        >
                          Withdrawal
                        </div>
                        <div
                          className={`${style.selectDiv} pl-3 font-medium`}
                          onClick={() => {
                            setSelectTransfer("Deposit");
                            setShowSelectTransfer(!showSelectTransfer);
                          }}
                        >
                          Deposit
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>

                <div className="bg-[#000000]/10 pt-4 rounded-b-[12px] md:rounded-b-[26px] space-y-1 md:space-y-3 px-2 py-3 md:px-6 md:py-4">
                  {selectTransfer === "Withdrawal" ? (
                    <QuickTransfer selectTransfer={selectTransfer} />
                  ) : (
                    <QuickDeposit />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Account Chart  */}

          <div className="z-10 w-full md:w-[50%]  ">
            <div className="bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
              <div
                className={`flex flex-col  space-y-1 md:space-y-8 px-2 py-3 md:px-2 md:py-4 bg-gradient-to-b from-[#000000]/80 from-80% via-[#29293e] via-80% to-[#29293e] to-20%   rounded-[12px] md:rounded-[26px]  p-4 text-white`}
              >
                <div
                  className={`md:px-8 leading-5 text-[9px] text-[#ffffff]/50 font-normal md:text-base lg:text-xl`}
                >
                  Equity Curve
                </div>

                <ChartPlotting
                  chartData={
                    performceAccountValueData && performceAccountValueData
                  }
                  titleOne="Account"
                />
              </div>
            </div>
            <LiveTrade tradeData={liveTradeData && liveTradeData} />
          </div>

          <div className="w-full md:w-[30%]">
            {/* Holdings  */}
            {!holdingData && !holdingData ? (
              <div className="w-full bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
                <div
                  className={`flex flex-col justify-center space-y-1 md:space-y-3 px-4 py-3 h-full md:px-10 md:py-4 bg-gradient-to-b from-[#000000]/80 from-10% via-[#29293e] via-80% to-[#29293e] to-20%   rounded-[12px] md:rounded-[26px]  p-4 text-white`}
                >
                  <div
                    className={`leading-5 text-[9px] text-[#ffffff] font-normal md:text-base lg:text-lg`}
                  >
                    Assets
                  </div>
                  <div className="flex flex-row">
                    <div className="w-[33%] text-xs text-[#ffffff]/50 font-light font-poppins">
                      Symbol
                    </div>
                    <div className="w-[33%] text-right text-xs text-[#ffffff]/50 font-light font-poppins">
                      Last
                    </div>
                    <div className="w-[33%] text-right text-xs text-[#ffffff]/50 font-light font-poppins">
                      Chg%
                    </div>
                  </div>
                  <div className="py-4 flex flex-row justify-center text-white text-xl md:text-2xl">
                    {isLoading === true ? (
                      <LoadingSpinner />
                    ) : (
                      "No Data Available"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
                <div
                  className={`flex flex-col justify-center space-y-1 md:space-y-3 px-4 py-3 h-full md:px-10 md:py-4 bg-gradient-to-b from-[#000000]/80 from-10% via-[#29293e] via-80% to-[#29293e] to-20%   rounded-[12px] md:rounded-[26px]  p-4 text-white`}
                >
                  <div
                    className={`leading-5 text-[9px] text-[#ffffff] font-normal md:text-base lg:text-lg`}
                  >
                    Assets
                  </div>
                  <div className="flex flex-row">
                    <div className="w-[33%] text-xs text-[#ffffff]/50 font-light font-poppins">
                      Symbol
                    </div>
                    <div className="w-[33%] text-right text-xs text-[#ffffff]/50 font-light font-poppins">
                      Last
                    </div>
                    <div className="w-[33%] text-right text-xs text-[#ffffff]/50 font-light font-poppins">
                      Chg%
                    </div>
                  </div>
                  {holdingData &&
                    holdingData.map((ele, i) => {
                      return (
                        <div
                          className={
                            i === holdingData.length - 1
                              ? "flex flex-row pb-2 items-center"
                              : "flex flex-row border-b border-[#ffffff]/10 pb-2 items-center"
                          }
                          key={i}
                        >
                          <div className="w-[33%] flex flex-row items-center text-sm text-[#ffffff] font-light font-poppins">
                            <img src={ele[3]} alt="USD" className="pr-1" />
                            {ele[0]}
                          </div>
                          <div className="w-[33%] text-right text-sm text-[#ffffff] font-light font-poppins">
                            {parseFloat(ele[1]).toFixed(2)}
                          </div>
                          <div
                            className={
                              parseFloat(ele[2]) > 0
                                ? "w-[33%] text-right text-sm text-[#3AC985] font-semibold font-poppins"
                                : parseFloat(ele[2]) === 0
                                ? "w-[33%] text-right text-sm text-[#ffffff] font-light font-poppins"
                                : "w-[33%] text-right text-sm text-[#A93333] font-semibold font-poppins"
                            }
                          >
                            {ele[2]}%
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {/* News data  */}
            <div className="mt-6 w-full bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
              <div
                className={`h-[400px]  px-6 md:pl-8  bg-gradient-to-b from-[#000000]/80 from-10% via-[#29293e] via-80% to-[#29293e] to-20%  rounded-[12px] md:rounded-[26px]  text-white`}
              >
                {!newsFeedData && !newsFeedData ? (
                  <div className="py-4">
                    <div className="text-white text-lg font-poppins">News</div>
                    <div className="py-4 flex flex-row justify-center text-white text-base md:text-lg">
                      {isLoadingNews === true ? (
                        <LoadingSpinner />
                      ) : (
                        "No News Available"
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col  bg-transparent  h-[400px] overflow-hidden py-4">
                    <div className="text-white text-lg font-poppins">News</div>
                    <div className="flex flex-col py-4 flex-1 space-y-2  bg-transparent  border-red-500  overflow-y-auto custom-scroll">
                      {newsFeedData &&
                        newsFeedData.map((ele, i) => {
                          return (
                            <a
                              className={
                                i === newsFeedData.length - 1
                                  ? "pb-2 items-center cursor-pointer"
                                  : "border-b border-[#ffffff]/10 pb-2 items-center cursor-pointer"
                              }
                              key={i}
                              href={ele.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="flex items-center">
                                <img src={NewsFeed} alt="NewsFeed" />
                                <span className="ml-2 text-xs text-[#ffffff]/30 font-light font-poppins">
                                  {moment(ele.pubDate).fromNow()}
                                </span>
                                <span className="ml-2 text-xs text-[#ffffff]/30 font-light font-poppins">
                                  {ele.creator}
                                </span>
                              </div>
                              <div className="mt-2 text-sm text-[#ffffff]/80 font-normal font-poppins">
                                {ele.title}
                              </div>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Economic Calender*/}
            <div className="mt-6 w-full bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
              <div
                className={`h-[400px]  px-6 md:pl-8  bg-gradient-to-b from-[#000000]/80 from-10% via-[#29293e] via-80% to-[#29293e] to-20%  rounded-[12px] md:rounded-[26px]  text-white`}
              >
                {!economicCalenderData && !economicCalenderData ? (
                  <div className="py-4">
                    <div className="text-white text-lg font-poppins">
                      Economic Calendar
                    </div>
                    <div className="py-4 flex flex-row justify-center text-white text-base md:text-lg">
                      {isLoadingCalender === true ? (
                        <LoadingSpinner />
                      ) : (
                        "No Economic Calendar Available"
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col  bg-transparent  h-[400px] overflow-hidden py-4">
                    <div className="text-white text-lg font-poppins">
                      Economic Calendar
                    </div>
                    <div className="flex flex-col py-4 flex-1 space-y-2  bg-transparent  border-red-500  overflow-y-auto custom-scroll">
                      {economicCalenderData &&
                        economicCalenderData.map((ele, i) => {
                          return (
                            <a
                              className={
                                i === economicCalenderData.length - 1
                                  ? "pb-2 items-center cursor-pointer"
                                  : "border-b border-[#ffffff]/10 pb-2 items-center cursor-pointer"
                              }
                              key={i}
                              href={ele.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="flex items-center">
                                <img src={NewsFeed} alt="NewsFeed" />
                                <span className="ml-2 text-xs text-[#ffffff]/30 font-light font-poppins">
                                  {moment(ele.pubDate).fromNow()}
                                </span>
                                <span className="ml-2 text-xs text-[#ffffff]/30 font-light font-poppins">
                                  {ele.creator}
                                </span>
                              </div>
                              <div className="mt-2 text-sm text-[#ffffff]/80 font-normal font-poppins">
                                {ele.title}
                              </div>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
