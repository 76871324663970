import React from "react";
import styles from "./Dashboard.module.css";
import LeftSideNav from "./LeftSideNav";
import Binance from "../Resources/Binance.svg";
import Wazirx from "../Resources/Wazirx.svg";
import HuobiGlobal from "../Resources/HuobiGlobal.svg";
import Kucoin from "../Resources/Kucoin.svg";
import Ftx from "../Resources/FTX.svg";
import Coinbase from "../Resources/Coinbase.svg";
import Bitfinix from "../Resources/Bitfinex.svg";
import Kraken from "../Resources/kraken.svg";
import Crypto from "../Resources/Cryptocom.svg";
import BitBns from "../Resources/Bitbns.svg";
import MobileNav from "../Header/MobileNav";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();

const dashboardstyles = {
  gradientBg: "bg-gradient-to-br from-[#332742] via-[#16161f] to-[#14141D]",
  exchangeName:
    "w-[33%]  px-6 flex items-center font-normal leading-6 text-[26px] text-white py-6",
};

const ExchangeIntegrate = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={`relative flex flex-row min-h-screen ${dashboardstyles.gradientBg}`}
      >
        
        <div className="lg:w-[20%] lg:flex hidden">
          <LeftSideNav />
        </div>

        <div className="w-full lg:w-[80%] lg:flex  lg:items-center">
          <div className="w-full lg:hidden h-[60px] sticky top-0 z-50 ">
            <MobileNav />
          </div>
          <div
            className={`relative w-full px-10 mt-20 lg:mt-0  lg:w-[80%] lg:mx-auto  `}
          >
             <div className="absolute -top-[420px] -left-[360px]  opacity-20 blur-sm mix-blend-multiply bg-gradient-to-b from-[#000000]/10 to-[#C16EF4]/20 w-[760px] h-[760px] rounded-full"></div>
            <div className={`bg-gradient-to-r from-[#1d1d2d]/90 to-[#202132]/30 backdrop-filter  backdrop-blur shadow-lg px-12 py-16 rounded-lg `}>
              
              <div className={`font-normal leading-6 text-[30px] px-6 mb-6 text-white`}>
                Exchange Integration
              </div>
              <div style={{ display: "flex" }}>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Binance}
                    alt="Exchanges"
                    className={styles.exchngaeImg}
                  />{" "}
                  Binance
                </div>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Wazirx}
                    alt="Exchanges"
                    className={styles.exchngaeImg}
                  />{" "}
                  Wazirx
                </div>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={HuobiGlobal}
                    alt="Exchanges"
                    className={styles.exchngaeImg}
                  />{" "}
                  HuobiGlobal
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Kucoin}
                    alt="Kucoin"
                    className={styles.exchngaeImg}
                  />{" "}
                  Kucoin
                </div>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img src={Ftx} alt="Ftx" className={styles.exchngaeImg} /> FTX
                </div>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Coinbase}
                    alt="Coinbase"
                    className={styles.exchngaeImg}
                  />{" "}
                  Coinbase
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Bitfinix}
                    alt="Bitfinix"
                    className={styles.exchngaeImg}
                  />{" "}
                  Bitfinex
                </div>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Kraken}
                    alt="Kraken"
                    className={styles.exchngaeImg}
                  />{" "}
                  Kraken
                </div>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={Crypto}
                    alt="Crypto"
                    className={styles.exchngaeImg}
                  />{" "}
                  Crypto.com
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className={`${dashboardstyles.exchangeName}`}>
                  <img
                    src={BitBns}
                    alt="Bitbns"
                    className={styles.exchngaeImg}
                  />{" "}
                  Bitbns
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
};
export default ExchangeIntegrate;
