import React from "react";

const CustomSlider = ({ sliderValue, setSliderValue, minValue, maxValue }) => {
  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  const percentage = ((sliderValue - minValue) / (maxValue - minValue)) * 100;

  // Dynamically style the background of the slider track
  const trackStyle = {
    background: `linear-gradient(to right, #818CEE ${percentage}%, #FFFFFF1A ${percentage}%)`,
  };

  return (
    <div className="flex items-center justify-center mt-2 z-10">
      <div className="w-[300px] md:w-[500px]">
        <input
          type="range"
          min={minValue}
          max={maxValue}
          value={sliderValue}
          onChange={handleSliderChange}
          className="range-input w-full appearance-none cursor-pointer h-2 bg-white/10 rounded-lg focus:outline-none"
          style={trackStyle}
          id="custom-slider"
        />
        <div className="flex justify-between text-white mt-2">
          <span>min: {minValue}</span>
          <span>max: {maxValue}</span>
        </div>
        <p className="text-center text-white mt-2">
          Value: {sliderValue !== null ? sliderValue : 0}
        </p>
      </div>
    </div>
  );
};

export default CustomSlider;
