import React, { useState, useEffect } from "react";
import axios from "axios";
const PnlValue = ({ ticker, buyValue, quantity, pnlCheck, tradeId }) => {
  const [currentPrice, setCurrentPrice] = useState();
  const getCurrentPrice = (ticker) => {
    let data = JSON.stringify({
      ticker: ticker,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://new-dashboard.server.sypto.xyz/api/admin/ticker/ticker-data",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setCurrentPrice(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCurrentPrice(ticker);
  }, [ticker]);

  const tickerCurrentPrice = currentPrice && currentPrice.close;

  const pnlValueCheck = (tickerCurrentPrice - buyValue) * quantity;
  const pnlValue = pnlValueCheck ? pnlValueCheck.toFixed(2) : "0";

  const initialTotalValue = buyValue * quantity;

  const pnlPercentCheck = (pnlValue / initialTotalValue) * 100;
  const pnlPercent = pnlPercentCheck ? pnlPercentCheck.toFixed(2) : "0";

  // upadte pnl

  return (
    <span>
      {pnlCheck === "1" ? (
        <span
          className={
            parseFloat(pnlValue) > 0
              ? "text-[#18b13f] font-bold"
              : parseFloat(pnlValue) < 0
              ? "text-[#eb323f] font-bold"
              : ""
          }
        >
          {pnlValue ? pnlValue : "0"}
        </span>
      ) : (
        <span
          className={
            parseFloat(pnlPercent) > 0
              ? "text-[#18b13f] font-bold"
              : parseFloat(pnlPercent) < 0
              ? "text-[#eb323f] font-bold"
              : ""
          }
        >
          {pnlPercent ? pnlPercent : "0"}%
        </span>
      )}
    </span>
  );
};

export default PnlValue;
