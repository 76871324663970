import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function login(username, password, otp) {
  const postData = {
    username,
    password,
    otp,
  };

  return axios.post(
    `https://new-dashboard.server.sypto.xyz/api/user/login`,
    postData
  );
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");

  // If token details are not found, perform logout
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  try {
    const tokenDetails = JSON.parse(tokenDetailsString);
    // Dispatch action to confirm login with token details
    dispatch(loginConfirmedAction(tokenDetails));
  } catch (error) {
    console.error("Error parsing token details:", error);
    // Perform logout if there's an error parsing token details
    dispatch(logout(history));
  }
}
