import React, { useState, useEffect } from "react";
import styles from "./login.module.css";

import logo from "../assests/Sanskar_logowotext.svg";
import UserIcon from "../assests/user.svg";
import LockIcon from "../assests/lock.svg";

import axios from "axios";
const ResetPassowrd = () => {
  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");
  const [confirmPassowrd, setConfirmPassword] = useState("");

  const [showMsg, setShowMsg] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [showButtonText, setShowButtonText] = useState(false);
  const handleSubmit = () => {
    setShowButtonText(true);
    if (password !== confirmPassowrd) {
      setErrorShow(true);
      setError("Password and Confirm Password does not match.");
      setShowButtonText(false);
    } else {
      setShow(true);
      let data = JSON.stringify({
        username: username,
        password: password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://new-dashboard.server.sypto.xyz/api/user/update-password",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setShow(true);
          setShowMsg("Your password has been successfully updated.");
          setShowButtonText(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (show) {
      setShow(true);
      const toRef = setTimeout(() => {
        setShow(false);
        setPassword("");
        setConfirmPassword("");
        setUserName("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [show]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);
  return (
    <div
      className={` bg-hero bg-no-repeat min-h-screen w-full bg-cover  flex `}
    >
      <div className={`flex w-full justify-center items-center `}>
        <div
          className={` ${styles.linearGradient}  w-[90%] md:w-[80%] lg:w-1/3 p-[4px] rounded-[26px] flex flex-col justify-center items-center`}
        >
          <div
            className={` ${styles.innerShadow}  bg-gray-900/75 rounded-[23px]  backdrop-filter backdrop-blur-md w-full flex flex-col justify-center items-center px-4 md:px-12 py-8 md:py-24`}
          >
            <div>
              <img src={logo} alt="Logo" width={100} />
            </div>
            <div className={`text-white text-xl mt-3`}>Sanskar Enterprises</div>
            <div className={styles.SignUpHeaad}>Reset your Password</div>
            <div className="text-[#ffffff]/50 mt-6">
              Last 3 passwords are not allowed.
            </div>
            <div
              className={`${styles.loginDiv} ${
                styles.resetDiv
              } border-b-[1px] border-[#ffffff1f]  ${
                error && " border-[#E43D3D]"
              }`}
            >
              <img src={UserIcon} alt="User Icon" className="w-5 h-5 " />
              <input
                type="text"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="username"
                className={styles.logininput}
              />
            </div>
            <div
              className={`${styles.loginDiv1} ${
                styles.resetDiv
              } border-b-[1px] border-[#ffffff1f]  ${
                error && " border-[#E43D3D]"
              }`}
            >
              <img src={LockIcon} alt="Lock Icon" className="w-5 h-5 " />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="New password"
                className={styles.logininput}
              />
            </div>
            <div
              className={`${styles.loginDiv1} ${
                styles.resetDiv
              } border-b-[1px] border-[#ffffff1f]  ${
                error && " border-[#E43D3D]"
              }`}
            >
              <img src={LockIcon} alt="Lock Icon" className="w-5 h-5 " />
              <input
                type="password"
                value={confirmPassowrd}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm password"
                className={styles.logininput}
              />
            </div>
            {errorShow ? (
              <div className={`ml-4 ${styles.errorMsg}`}>{error}</div>
            ) : null}
            {show === true ? (
              <div className={`mt-6 text-sm text-[#51DB74]`}>{showMsg}</div>
            ) : null}
            <button className={styles.loginButton} onClick={handleSubmit}>
              {showButtonText === true ? (
                <div className="flex flex-row justify-center">
                  <svg
                    className="h-8 w-8 text-[#ffffff] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassowrd;
