import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../Home/Dashboard.module.css";
import { motion } from "framer-motion";
import { UseTickerTistData } from "../Utils/AllData";
import CustomSlider from "../components/custom-slider";
import LoadingSpinner from "../components/loading-spinner";
import Moment from "moment";

import ApprovedTick from "../assests/ArrovedTick.svg";
import PendingTick from "../assests/Pending.svg";

import { ChevronDownIcon, ChevronUpIcon, MinusCircle } from "lucide-react";
import PnlValue from "./PnlValue";
import LoadingSpinnerNew from "../components/LoadingSpinner2";

const style = {
  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#000000]/80 hover:text-white/85  ease-linear duration-150 cursor-pointer",
  mainDiv: "mt-10 flex flex-col md:flex-row md:items-center ",
  labelText: "w-full md:w-[25%] text-[#ffffff]/80 text-base font-poppins",
  inputDiv:
    "mt-4 md:mt-0 md:ml-32 flex flex-row items-center border-b border-[#ffffff]/20 pb-2",
  inputBox:
    "w-[300px] md:w-[500px] pl-4 bg-transparent text-[#ffffff]/80 font-poppins ",
  inputBox2:
    "w-[300px] md:w-[200px] pl-4 bg-transparent text-[#ffffff]/80 font-poppins z-20",
  tableData: "text-[14px] text-white font-poppins py-4 px-2 min-w-[120px]",
  selectDiv1:
    "flex items-center px-2 py-2 text-[14px] text-[#E43D3D] border-b border-[#ffffff]/20 hover:rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10   hover:border-[#818CED]/10 ease-linear duration-150 cursor-pointer",
  selectDiv2:
    "flex items-center px-2 py-2 text-[14px] text-[#18b13f] hover:rounded-md mx-3 md-3  hover:bg-[#818CED]/10  hover:border-[#818CED]/10 ease-linear duration-150 cursor-pointer",
  selectDiv3:
    "flex items-center px-2 py-2 border-b border-[#ffffff]/20 text-[14px] text-[#D7BD34] hover:rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10  hover:border-[#818CED]/10 ease-linear duration-150 cursor-pointer",
};

const TickerBuySell = () => {
  const { data: allTickers } = UseTickerTistData();

  const tickerListData = allTickers && allTickers.data.values;

  const currentDate = new Date();
  const [tickerPrice, setTickerPrice] = useState(null);
  const newDate = Moment(String(currentDate)).format("MM/DD/YYYY");
  const [tickerList, setTicketList] = useState("");
  const [position, setPosition] = useState("");
  const [sliderValue, setSliderValue] = useState(
    tickerPrice === null ? 0 : tickerPrice?.low
  );
  const [tickerQunatity, setTickerQunatity] = useState("");

  const [showPosition, setShowPosition] = useState(false);
  const [show, setShow] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [error, setError] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [showMsg, setShowMsg] = useState("");

  const [showButtonText, setShowButtonText] = useState(false);
  const generateRandomString = () => {
    const length = 10;
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSubmit = () => {
    const tradeId = generateRandomString();
    setShowButtonText(true);
    if (!tickerList || !position || !tickerQunatity) {
      setErrorShow(true);
      setError("All Fields are required");
      setShowButtonText(false);
    } else {
      axios
        .post(
          "https://new-dashboard.server.sypto.xyz/api/admin/live/live-trade",
          {
            date: newDate,
            symbol: tickerList,
            position: position,
            buy_value: sliderValue,
            quantity: tickerQunatity,
            pnl_value: "0",
            pnlPercent: "0",
            status: "Pending",
            tradeId: tradeId,
          }
        )
        .then((res) => {
          setShowComponent(true);
          setShowMsg("You have successfully Placed your trade.");
          setShowButtonText(false);
          getAllLiveTrade();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setPosition("");
        setTickerQunatity("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showComponent]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);

  const [loading, setLoading] = useState(false);
  const handleTickerSelection = (ticker) => {
    // Close the dropdown
    setShow(false);
    // Update the ticker list
    setTicketList(ticker);
    // Fetch the ticker price
    getTickerPrice(ticker);
  };
  const getTickerPrice = (ticker) => {
    setLoading(true);
    let data = JSON.stringify({
      ticker: ticker,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://new-dashboard.server.sypto.xyz/api/admin/ticker/ticker-data",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setTickerPrice(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // for approved trade

  const [allTrade, setAllTrade] = useState();
  const [allTradeLoading, setAllTradeLoading] = useState(true);
  const getAllLiveTrade = () => {
    axios
      .get(
        "https://new-dashboard.server.sypto.xyz/api/common-data/live/live-trade"
      )
      .then((res) => {
        setAllTrade(res.data);
        setAllTradeLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllLiveTrade();
  }, []);

  const [showTradeStatus, setShowTradeStatus] = useState([]);
  const [loadingStates, setLoadingStates] = useState(
    Array(allTrade && allTrade.length).fill(false)
  );

  const toggleTradeStatus = (index) => {
    const newShowTradeStatus = [...showTradeStatus];
    newShowTradeStatus[index] = !newShowTradeStatus[index];
    setShowTradeStatus(newShowTradeStatus);
  };

  const handleTradeStatus = (i, status) => {
    setLoadingStates((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[i] = true;
      return newLoadingStates;
    });
    axios
      .post(
        "https://new-dashboard.server.sypto.xyz/api/admin/live/approve/live-trade-status",
        {
          status: status,
          tradeId: allTrade && allTrade[i][8],
        }
      )
      .then((res) => {
        setShowTradeStatus(new Array(allTrade.length).fill(false));
        setLoadingStates((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[i] = false;
          return newLoadingStates;
        });
        getAllLiveTrade();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // for close the trade

  const [showSellButton, setShowSellButton] = useState(false);

  const [sellTradeId, setSellTradeId] = useState();

  const handleSellTicker = (ticker, position, quantity, tradeId) => {
    setShowSellButton(true);
    setTicketList(ticker);
    setPosition(position);
    setTickerQunatity(quantity);
    setSellTradeId(tradeId);
    // Fetch the ticker price
    getTickerPrice(ticker);
  };
  const [sellLoader, setSellLoader] = useState(false);
  const [sellState, setSellState] = useState(false);

  const [showSuccessFullText, setShowSuccessfullText] = useState();

  const handleTradeSell = () => {
    setSellLoader(true);
    axios
      .post(
        "https://new-dashboard.server.sypto.xyz/api/admin/history/complete/closed-trade",
        {
          date: newDate,
          sell_value: sliderValue,
          sell_quantity: tickerQunatity,
          tradeId: sellTradeId,
        }
      )
      .then((res) => {
        setSellLoader(false);
        setShowSuccessfullText("You have successfully placed your sell order");
        setSellState(true);
        getAllLiveTrade();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (sellState) {
      setSellState(true);
      const toRef = setTimeout(() => {
        setSellState(false);
        setShowSellButton(false);

        setPosition("");
        setTickerQunatity("");

        clearTimeout(toRef);
      }, 4000);
    }
  }, [sellState]);

  useEffect(() => {
    if (tickerPrice !== null) {
      setSliderValue(tickerPrice.low);
    }
  }, [tickerPrice]);
  return (
    <div className="flex pt-16 md:pt-12 px-3 md:px-12 min-h-screen">
      <div className="relative w-full  pt-10 pb-2 md:pt-12 md:pb-4 lg:pt-16 lg:pb-8">
        <div className="absolute -top-[450px] -left-[200px] z-0  blur-box w-[460px] h-[560px] md:w-[760px] md:h-[760px] rounded-full  filter blur-2xl opacity-90 "></div>

        <div className="flex flex-col  relative z-10 px-6 pb-4 mt-4  md:space-y-12 md:py-8 md:px-10 md:mt-6 rounded-[16px]  border-[1px]  border-[#818CEE]/20">
          <div className="flex absolute z-10  top-0 left-0 w-full h-full  rounded-[16px] bg-gradient-to-br from-[#818CED]/10 to-[#818CED]/0 ">
            <div className="hidden md:flex md:w-[30%] h-full bg-[#000000]/10  rounded-l-[16px] "></div>
            <div className="hidden md:flex md:w-[70%] h-full   rounded-r-[16px] "></div>
          </div>
          <div className={style.mainDiv}>
            <div className={style.labelText}>Ticker List :</div>

            <div
              className={`z-50 relative ${style.inputDiv} cursor-pointer  ${
                error && " border-[#E43D3D]"
              }`}
            >
              {showSellButton === true ? (
                <div className={`${style.inputBox} flex justify-between`}>
                  {tickerList ? (
                    <div className={styles.dropDownSelection}>{tickerList}</div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select your Ticker
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {show ? (
                      <ChevronUpIcon size={20} color="#fff" />
                    ) : (
                      <ChevronDownIcon size={20} color="#fff" />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShow(!show);
                  }}
                  className={`${style.inputBox} flex justify-between`}
                >
                  {tickerList ? (
                    <div className={styles.dropDownSelection}>{tickerList}</div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select your Ticker
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {show ? (
                      <ChevronUpIcon size={20} color="#fff" />
                    ) : (
                      <ChevronDownIcon size={20} color="#fff" />
                    )}
                  </div>
                </div>
              )}

              {show && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  exit={{ y: -50, opacity: 0 }}
                  className={`absolute right-0 top-12 bg-[#14141D] flex flex-col w-[100%] pt-2 pb-2 z-50 ${styles.dropdownBox2}`}
                >
                  {tickerListData &&
                    tickerListData.map((ele, i) => {
                      return (
                        <div
                          className={`${style.selectDiv} pl-3 font-medium`}
                          onClick={() => handleTickerSelection(ele[1])}
                          key={i}
                        >
                          {ele[0]}
                        </div>
                      );
                    })}
                </motion.div>
              )}
            </div>
            <div
              className={`pt-4 md:pt-0 relative ${
                style.inputDiv
              } cursor-pointer  ${error && " border-[#E43D3D]"}`}
            >
              {showSellButton === true ? (
                <div className={`${style.inputBox2} flex justify-between`}>
                  {position ? (
                    <div className={styles.dropDownSelection}>{position}</div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Position
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showPosition ? (
                      <ChevronUpIcon size={20} color="#fff" />
                    ) : (
                      <ChevronDownIcon size={20} color="#fff" />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShowPosition(!showPosition);
                  }}
                  className={`${style.inputBox2} flex justify-between`}
                >
                  {position ? (
                    <div className={styles.dropDownSelection}>{position}</div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Position
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showPosition ? (
                      <ChevronUpIcon size={20} color="#fff" />
                    ) : (
                      <ChevronDownIcon size={20} color="#fff" />
                    )}
                  </div>
                </div>
              )}

              {showPosition && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  exit={{ y: -50, opacity: 0 }}
                  className={`absolute right-0 top-12 bg-[#14141D] flex flex-col w-[100%] pt-2 pb-2 z-50  ${styles.dropdownBox2}`}
                >
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setPosition("LONG");
                      setShowPosition(!showPosition);
                    }}
                  >
                    LONG
                  </div>
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setPosition("SHORT");
                      setShowPosition(!showPosition);
                    }}
                  >
                    SHORT
                  </div>
                </motion.div>
              )}
            </div>
          </div>

          <div className={`${style.mainDiv}`}>
            <div className={style.labelText}>Buying Price :</div>
            {loading === true ? (
              <div className={`${style.inputDiv} border-none`}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={`${style.inputDiv}  border-none`}>
                <CustomSlider
                  tickerPrice={tickerPrice}
                  sliderValue={sliderValue}
                  setSliderValue={setSliderValue}
                  minValue={tickerPrice ? `${tickerPrice.low}` : 0}
                  maxValue={tickerPrice ? `${tickerPrice.high}` : 100}
                />
              </div>
            )}
          </div>

          <div className={`z-10 ${style.mainDiv}`}>
            <div className={style.labelText}>Quantity :</div>
            <div>
              <div
                className={`z-10 ${style.inputDiv}   ${
                  error && " border-[#E43D3D]"
                }`}
              >
                <input
                  type="number"
                  value={tickerQunatity}
                  placeholder="Enter shares quantity (e.g., 10, 50, 100)."
                  className={style.inputBox}
                  onChange={(e) => setTickerQunatity(e.target.value)}
                />
              </div>
            </div>
            <div className={`z-10 md:ml-32 md:-mt-4 ${style.mainDiv}`}>
              <div className={style.labelText}></div>
              {showSellButton === true ? (
                <button
                  className="text-white rounded-[24px] bg-[#E43D3D] px-12 py-3 font-bold"
                  onClick={handleTradeSell}
                >
                  {sellLoader === true ? <LoadingSpinnerNew /> : "Sell"}
                </button>
              ) : (
                <button
                  className="text-white rounded-[24px] bg-[#409538] px-12 py-3 font-bold"
                  onClick={handleSubmit}
                >
                  {showButtonText === true ? <LoadingSpinnerNew /> : "Buy"}
                </button>
              )}
            </div>
          </div>

          <div className={`md:-mt-4 flex flex-col md:flex-row`}>
            <div className={style.labelText}></div>
            {showComponent === true ? (
              <div className="md:ml-32 text-base text-[#18b13f] font-poppins">
                {showMsg}
              </div>
            ) : null}

            {errorShow === true ? (
              <div className="md:ml-32 text-base text-[#E43D3D] font-poppins">
                {error}
              </div>
            ) : null}
            {sellState === true ? (
              <div className="md:ml-32 text-base text-[#18b13f] font-poppins">
                {showSuccessFullText}
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-6 text-3xl text-white font-semibold font-montserrat">
          All Live Trade
        </div>
        {!allTrade && !allTrade ? (
          <div className=" mt-10 w-full custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
            <table className="  text-sm w-full relative  z-0">
              <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
                <tr className="text-white ">
                  <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                    DATE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    SYMBOL
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    POSITION
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    BUY VALUE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    QUANTITY
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    PNL VALUE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    PNL(in%)
                  </th>
                  <th className="py-6 text-right px-2 pr-6 text-[14px] font-medium">
                    STATUS
                  </th>
                </tr>
              </thead>
            </table>
            <span className="w-full text-white text-2xl md:text-4xl flex flex-row justify-center py-16">
              {allTradeLoading === true ? (
                <LoadingSpinner />
              ) : (
                "No Data Available"
              )}
            </span>
          </div>
        ) : (
          <div className=" mt-6 w-full custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
            <table className="text-sm w-full relative  z-0">
              <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
                <tr className="text-[#ffffff]/60 ">
                  <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                    DATE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    SYMBOL
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    POSITION
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    BUY VALUE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    QUANTITY
                  </th>
                  <th className="text-left py-6 px-2 text-[14px] font-medium">
                    PNL VALUE
                  </th>
                  <th className="text-center py-6 text-left px-2 text-[14px] font-medium">
                    PNL(%)
                  </th>
                  <th className="py-6 text-right px-2 pr-6 text-[14px] font-medium">
                    Status
                  </th>
                  <th className="py-6 text-right px-2 pr-6 text-[14px] font-medium">
                    Close
                  </th>
                </tr>
              </thead>

              <tbody className="bg-gradient-to-b from-[#202132] via-[#141B22]  to-[#000000]/10 ">
                {allTrade &&
                  allTrade
                    .sort((a, b) => {
                      // Assuming the date is the first item in each dataItem array
                      const dateA = new Date(a[0]);
                      const dateB = new Date(b[0]);
                      return dateB - dateA; // Sort in descending order (latest dates first)
                    })
                    .map((dataItem, i) => {
                      return (
                        <tr
                          key={i}
                          className=" z-0 border-b border-[#818CEE]/20  "
                        >
                          <td className={`${style.tableData} pl-4`}>
                            {Moment(String(dataItem[0])).format("DD-MM-YYYY")}
                          </td>
                          <td className={style.tableData}>{dataItem[1]}</td>
                          <td className={style.tableData}>{dataItem[2]}</td>
                          <td className={style.tableData}>{dataItem[3]}</td>
                          <td className={style.tableData}>{dataItem[4]}</td>
                          <td className={`text-left ${style.tableData}`}>
                            <PnlValue
                              ticker={dataItem[1]}
                              buyValue={dataItem[3]}
                              quantity={dataItem[4]}
                              pnlCheck="1"
                              tradeId={dataItem[8]}
                            />
                          </td>

                          <td className={`text-center ${style.tableData}`}>
                            <PnlValue
                              ticker={dataItem[1]}
                              buyValue={dataItem[3]}
                              quantity={dataItem[4]}
                              tradeId={dataItem[8]}
                            />
                          </td>
                          <td className={`${style.tableData} items-center`}>
                            <span className="relative">
                              {loadingStates[i] ? (
                                <span className="flex flex-row justify-center">
                                  <svg
                                    className="h-6 w-6 text-[#E43D3D] animate-spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </span>
                              ) : dataItem[7] === "Approved" ? (
                                <span className="text-[#18b13f] flex flex-row justify-center items-center">
                                  {" "}
                                  <img
                                    src={ApprovedTick}
                                    alt="Approved Tick"
                                    className="mr-2"
                                  />
                                  {dataItem[7]}
                                </span>
                              ) : dataItem[7] === "Declined" ? (
                                <span className="text-[#E43D3D] flex flex-row justify-center items-centerr">
                                  <MinusCircle className="mr-2 h-5 h-5" />
                                  {dataItem[7]}
                                </span>
                              ) : (
                                <span className="">
                                  <span
                                    onClick={() => toggleTradeStatus(i)}
                                    className={`flex flex-row justify-center text-[#D7BD34] pb-2 cursor-pointer items-center`}
                                  >
                                    <span className="flex ">
                                      {" "}
                                      <img
                                        src={PendingTick}
                                        alt="Pending Tick"
                                        className="mr-2"
                                      />{" "}
                                      Pending
                                    </span>

                                    <span
                                      className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                                    >
                                      {showTradeStatus[i] ? (
                                        <ChevronUpIcon className="w-5 h-5 text-white/50" />
                                      ) : (
                                        <ChevronDownIcon className="w-5 h-5 text-white/50" />
                                      )}
                                    </span>
                                  </span>
                                  {showTradeStatus[i] && (
                                    <motion.span
                                      initial={{ y: -50, opacity: 0 }}
                                      animate={{ y: 0, opacity: 1 }}
                                      transition={{
                                        duration: 0.5,
                                        type: "spring",
                                      }}
                                      exit={{ y: -50, opacity: 0 }}
                                      className={`absolute right-0 top-8 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                                    >
                                      <span
                                        className={`${style.selectDiv3} pl-3 font-medium`}
                                        onClick={() =>
                                          handleTradeStatus(i, "Pending")
                                        }
                                      >
                                        <img
                                          src={PendingTick}
                                          alt="Pending Tick"
                                          className="mr-2"
                                        />
                                        Pending
                                      </span>
                                      <span
                                        className={`${style.selectDiv1} pl-3 font-medium`}
                                        onClick={() =>
                                          handleTradeStatus(i, "Declined")
                                        }
                                      >
                                        <MinusCircle className="mr-2 w-4 h-4" />
                                        Declined
                                      </span>
                                      <span
                                        className={`text-[#18b13f] ${style.selectDiv2} pl-3 font-medium`}
                                        onClick={() =>
                                          handleTradeStatus(i, "Approved")
                                        }
                                      >
                                        <img
                                          src={ApprovedTick}
                                          alt="Approved Tick"
                                          className="mr-2"
                                        />
                                        Approved
                                      </span>
                                    </motion.span>
                                  )}
                                </span>
                              )}
                            </span>
                          </td>
                          <td className={`text-right ${style.tableData} pr-6`}>
                            <span
                              className="bg-[#E43D3D] text-white font-semibold px-3 py-1 rounded-lg cursor-pointer"
                              onClick={() =>
                                handleSellTicker(
                                  dataItem[1],
                                  dataItem[2],
                                  dataItem[4],
                                  dataItem[8]
                                )
                              }
                            >
                              SELL
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default TickerBuySell;
