import React, { useState, useEffect } from "react";
import styles from "./login.module.css";
import { AiOutlineMail } from "react-icons/ai";
import axios from "axios";
import Logo from "../assests/Sanskar_logowotext.svg";

const ForgotPassowrd = () => {
  const [email, setEmail] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [showButtonText, setShowButtonText] = useState(false);
  const handleSubmit = () => {
    setShowButtonText(true);
    if (!email) {
      setErrorShow(true);
      setError("Please Enter the email");
      setShowButtonText(false);
    } else
      axios
        .post("https://new-dashboard.server.sypto.xyz/send-mail", {
          email: email,
        })
        .then((res) => {
          setShow(true);
          setShowMsg("Email has been sent to your email");
          setShowButtonText(false);
        })
        .catch((err) => {
          console.log("err", err);
          setErrorShow(true);
          setError(err.response.data.msg);
          setShowButtonText(false);
        });
  };
  useEffect(() => {
    if (show) {
      setShow(true);
      const toRef = setTimeout(() => {
        setShow(false);
        setEmail("");

        clearTimeout(toRef);
      }, 4000);
    }
  }, [show]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        setShowButtonText(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [errorShow]);
  return (
    <div
      className={` bg-hero bg-no-repeat min-h-screen w-full bg-cover  flex `}
    >
      <div className={`flex w-full justify-center items-center `}>
        <div
          className={` ${styles.linearGradient}  w-[90%] md:w-[80%] lg:w-1/3 p-[4px] rounded-[26px] flex flex-col justify-center items-center`}
        >
          <div
            className={` ${styles.innerShadow}  bg-gray-900/75 rounded-[23px]  backdrop-filter backdrop-blur-md w-full flex flex-col justify-center items-center px-4 md:px-6 py-8 md:py-24`}
          >
            <div>
              <img src={Logo} alt="Logo" width={100} />
            </div>
            <div className={`text-white text-xl mt-3 font-poppins`}>
              Sanskar Enterprises
            </div>
            <div
              className={
                "mt-8 text-xl md:text-3xl text-white font-poppins font-semibold"
              }
            >
              Forgot your Password?
            </div>
            <div className="text-[#ffffff]/50 text-sm mt-3 flex justify-center">
              Don’t worry. We’ll send you an email to reset your password.
            </div>
            <div
              className={`${
                styles.loginDiv
              } border-b-[1px] border-[#ffffff1f]  ${
                error && " border-[#E43D3D]"
              }`}
            >
              <AiOutlineMail
                style={{ color: "#E43D3D" }}
                className={styles.usericons}
              />
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className={`${styles.logininput} ${
                  error ? "border-red-500" : ""
                }`}
              />
            </div>
            {errorShow === true ? (
              <div className={`mt-6 text-sm text-[#E43D3D]`}>{error}</div>
            ) : null}
            {show === true ? (
              <div className={`mt-6 text-sm text-[#51DB74]`}>{showMsg}</div>
            ) : null}
            <button className={styles.loginButton} onClick={handleSubmit}>
              {showButtonText === true ? (
                <div className="flex flex-row justify-center">
                  <svg
                    className="h-8 w-8 text-[#ffffff] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassowrd;
