import React from "react";
import { UseTradeHistotyStatsData } from "../Utils/AllData";

const DashboardData = () => {
  const { data: tradeHistoryStats } = UseTradeHistotyStatsData();
  const dashboardData = tradeHistoryStats && tradeHistoryStats.data;

  return (
    <div className="px-4 md:px-6">
      {dashboardData &&
        dashboardData.map((ele, i) => {
          return (
            <div
              className="flex space-x-6 lg:pl-6 lg:flex-row w-full lg:space-x-8"
              key={i}
            >
              <div className="w-full lg:w-[33%] h-[70px] md:h-[140px] bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
                <div
                  className={`flex flex-col justify-center space-y-1 md:space-y-3 px-2 py-3 h-full md:px-10 md:py-4 bg-gradient-to-b from-[#000000]/80 from-10% via-[#29293e] via-80% to-[#29293e] to-20%    rounded-[12px] md:rounded-[26px]  text-white`}
                >
                  <div
                    className={`leading-5 text-[10px] text-[#ffffff]/50 font-normal md:text-base lg:text-xl`}
                  >
                    ROI ( Return on Investment )
                  </div>
                  <div
                    className={`leading-5 text-md font-semibold text-white md:font-semibold lg:text-[42px] md:pt-2`}
                  >
                    {ele[0]}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-[33%] h-[70px] md:h-[140px] bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px] rounded-[12px] md:rounded-[26px]">
                <div
                  className={`flex flex-col justify-center space-y-1 md:space-y-3 px-2 py-3 h-full md:px-10 md:py-4 bg-gradient-to-b from-[#000000]/80 from-10% via-[#29293e] via-80% to-[#29293e] to-20%  rounded-[12px] md:rounded-[26px]  p-4 text-white`}
                >
                  <div
                    className={`leading-5 text-[10px] text-[#ffffff]/50 font-light md:text-base lg:text-xl`}
                  >
                    ROI ( In Percentage % )
                  </div>
                  <div
                    className={`leading-5 text-md font-semibold text-white md:font-semibold lg:text-[42px] md:pt-2`}
                  >
                    {ele[1]}%
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default DashboardData;
