import React, { useState, useEffect } from "react";
import styles from "./login.module.css";
import Logo from "../assests/Sanskar_logowotext.svg";
import { Link } from "react-router-dom";

import { connect, useDispatch } from "react-redux";
import {
  loginAction,
  loginFailedAction,
} from "../store/actions/AuthActions.js";
import UserIcon from "../assests/email.svg";
import LockIcon from "../assests/lock.svg";

import OtpIcon from "../assests/OTP.svg";

import axios from "axios";

const Login = (props) => {
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [loadingOtp, setLoadingOtp] = useState(false);
  useEffect(() => {
    if (props.errorMessage != null) {
      setError(props.errorMessage);
      loginFailedAction(dispatch);
    }
  }, [props.errorMessage, dispatch]);

  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const handleOtp = async () => {
    setLoadingOtp(true);
    if (!username.length || !password.length) {
      setError("Email and password are required!");
      setLoadingOtp(false);
    } else {
      const postData = {
        username,
        password,
      };
      axios
        .post("https://new-dashboard.server.sypto.xyz/api/user/otp", postData)
        .then((res) => {
          setShowOtp(true);
          setLoadingOtp(false);
        })
        .catch((err) => {
          console.log("erroe", err);
        });
    }
  };
  // handle button click of login form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    if (otp.length !== 6) {
      setError("Otp is Wrong");
      setLoading(false);
    } else {
      await dispatch(loginAction(username, password, otp));
    }
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
      const toRef = setTimeout(() => {
        setLoading(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [loading]);

  return (
    <div
      className={` bg-hero bg-no-repeat min-h-screen w-full bg-cover  flex `}
    >
      <div className={`flex w-full justify-center items-center `}>
        <div
          className={` ${styles.linearGradient}  w-[90%] md:w-[80%] lg:w-1/3 p-[4px] rounded-[26px] flex flex-col justify-center items-center`}
        >
          {showOtp === true ? (
            <div
              className={` ${styles.innerShadow}  bg-gray-900/75 rounded-[23px]  backdrop-filter backdrop-blur-md w-full flex flex-col justify-center items-center px-4 md:px-12 py-8 md:py-24`}
            >
              <img src={Logo} alt="Logo" width={100} />

              <div className="text-white text-[22px] py-4 font-semibold">
                Sanskar Enterprises
              </div>
              <div
                className={`${
                  styles.loginDiv
                } border-b-[1px] border-[#ffffff1f]  ${
                  error && " border-[#E43D3D]"
                }`}
              >
                <img src={OtpIcon} alt="User Icon" className="w-5 h-5 " />
                <input
                  type="number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter Otp"
                  className={`${styles.logininput} `}
                />
              </div>

              {error ? (
                <div className="absolute top-4 right-4 py-3 px-3 font-normal text-white text-xs bg-[#E43D3D]/20 border-l-[8px] border-[#E43D3D]">
                  {error}
                </div>
              ) : null}
              {loading === true ? (
                <button className={styles.loginButton}>
                  <div className="flex flex-row justify-center">
                    <svg
                      className="h-8 w-8 text-[#ffffff] animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                </button>
              ) : (
                <button className={styles.loginButton} onClick={handleSubmit}>
                  Verify Otp
                </button>
              )}
            </div>
          ) : (
            <div
              className={` ${styles.innerShadow}  bg-gray-900/75 rounded-[23px]  backdrop-filter backdrop-blur-md w-full flex flex-col justify-center items-center px-4 md:px-12 py-8 md:py-24`}
            >
              <img src={Logo} alt="Logo" width={100} />

              <div className="text-white text-[22px] py-4 font-semibold">
                Sanskar Enterprises
              </div>

              <div
                className={`${
                  styles.loginDiv
                } border-b-[1px] border-[#ffffff1f]  ${
                  error && " border-[#E43D3D]"
                }`}
              >
                <img src={UserIcon} alt="User Icon" className="w-5 h-5 " />
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Enter Email"
                  className={`${styles.logininput} `}
                />
              </div>
              <div
                className={`${
                  styles.loginDiv1
                } border-b-[1px] border-[#ffffff1f]  ${
                  error && " border-[#E43D3D]"
                }`}
              >
                <img src={LockIcon} alt="Lock Icon" className="w-5 h-5 " />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  className={`${styles.logininput} ${
                    error ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div className="w-full text-[#e43d3d] text-sm mt-8 flex justify-end">
                <Link to="/forgot-password" className={`${styles.forgotText}`}>
                  Forgot your password?
                </Link>
              </div>
              {error ? (
                <div className="absolute top-4 right-4 py-3 px-3 font-normal text-white text-xs bg-[#E43D3D]/20 border-l-[8px] border-[#E43D3D]">
                  Email and password are required!
                </div>
              ) : null}

              {loadingOtp === true ? (
                <button className={styles.loginButton}>
                  <div className="flex flex-row justify-center">
                    <svg
                      className="h-8 w-8 text-[#ffffff] animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                </button>
              ) : (
                <button className={styles.loginButton} onClick={handleOtp}>
                  Login
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    errorMessage: state.auth.errorMessage,
  };
};

export default connect(mapStateToProps)(Login);
