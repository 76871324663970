import React from "react";
import Moment from "moment";

const style = {
  tableData: "text-[14px] text-white font-poppins py-4 px-2 min-w-[120px]",
};
const TradeData = ({ tradeDataAll }) => {
  const tradeData =
    tradeDataAll &&
    tradeDataAll.filter((innerArray) => innerArray[9] === "Approved");

  return (
    <>
      {!tradeData && !tradeData ? (
        <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)] custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
          <table className="  text-sm w-full relative  z-0">
            <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
              <tr className="text-white ">
                <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                  DATE
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  SYMBOL
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  POSITION
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  ENTRY POINT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  QUANTITY
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  EXIT POINT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  QUANTITY
                </th>
                <th className="py-6 text-right px-2 text-[14px] font-medium">
                  PNL VALUE
                </th>
                <th className="py-6 text-right pr-4 text-[14px] font-medium rounded-tr-lg ">
                  PNL(in%)
                </th>
              </tr>
            </thead>
          </table>
          <span className="w-full text-white text-2xl md:text-4xl flex flex-row justify-center py-16">
            No Data Available
          </span>
        </div>
      ) : tradeData && tradeData.length === 0 ? (
        <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)] custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
          <table className="  text-sm w-full relative  z-0">
            <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
              <tr className="text-white ">
                <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                  DATE
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  SYMBOL
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  POSITION
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  ENTRY POINT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  QUANTITY
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  EXIT POINT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  QUANTITY
                </th>
                <th className="py-6 text-right px-2 text-[14px] font-medium">
                  PNL VALUE
                </th>
                <th className="py-6 text-right pr-4 text-[14px] font-medium rounded-tr-lg ">
                  PNL(in%)
                </th>
              </tr>
            </thead>
          </table>
          <span className="w-full text-white text-2xl md:text-4xl flex flex-row justify-center py-16">
            No Data Available
          </span>
        </div>
      ) : (
        <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)] custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
          <table className="  text-sm w-full relative  z-0">
            <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
              <tr className="text-[#ffffff]/60 ">
                <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                  DATE
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  SYMBOL
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  POSITION
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  ENTRY POINT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  QUANTITY
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  EXIT POINT
                </th>
                <th className="py-6 text-left px-2 text-[14px] font-medium">
                  QUANTITY
                </th>
                <th className="py-6 text-right px-2 text-[14px] font-medium">
                  PNL VALUE
                </th>
                <th className="py-6 text-right pr-4 text-[14px] font-medium rounded-tr-lg ">
                  PNL(in%)
                </th>
              </tr>
            </thead>

            <tbody className="bg-gradient-to-b from-[#202132] via-[#141B22]  to-[#000000]/10 ">
              {tradeData &&
                tradeData.map((dataItem, i) => {
                  const pnlPercent = dataItem[8] && dataItem[8];
                  return (
                    <tr key={i} className=" z-0 border-b border-[#818CEE]/20  ">
                      <td className={`${style.tableData} pl-4`}>
                        {Moment(String(dataItem[0])).format("DD-MM-YYYY")}
                      </td>
                      <td className={style.tableData}>{dataItem[1]}</td>
                      <td className={style.tableData}>{dataItem[2]}</td>
                      <td className={style.tableData}>{dataItem[3]}</td>
                      <td className={style.tableData}>
                        {dataItem[4].replace("₹", "")}
                      </td>
                      <td className={style.tableData}>{dataItem[5]}</td>
                      <td className={style.tableData}>
                        {dataItem[6].replace("₹", "")}
                      </td>
                      <td
                        className={
                          parseFloat(dataItem[7]) > 0
                            ? `text-right text-[#18b13f] text-[14px] font-poppins py-4 px-2 min-w-[120px]`
                            : parseFloat(dataItem[7]) < 0
                            ? `text-right text-[#eb323f] text-[14px]  font-poppins py-4 px-2 min-w-[120px]`
                            : `text-right ${style.tableData} pr-4`
                        }
                      >
                        {dataItem[7]}
                      </td>
                      <td
                        className={
                          parseFloat(dataItem[8]) > 0
                            ? `text-right text-[#18b13f] text-[14px]  font-poppins py-4 px-2 min-w-[120px] pr-4`
                            : parseFloat(dataItem[8]) < 0
                            ? `text-right text-[#eb323f] text-[14px] font-poppins py-4 px-2 min-w-[120px] pr-4`
                            : `text-right ${style.tableData} pr-4`
                        }
                      >
                        {parseFloat(pnlPercent).toFixed(2)} %
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TradeData;
