import { login, saveTokenInLocalStorage } from "../../services/AuthService";

export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function logout() {
  localStorage.removeItem("userDetails");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(username, password, otp) {
  return (dispatch) => {
    login(username, password, otp)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        dispatch(loginConfirmedAction(response.data));
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAILED_ACTION,
          payload: err.response.data.msg,
        });
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
