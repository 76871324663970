import React from "react";
import { withRouter } from "react-router-dom";
import LeftSideNav from "../Home/LeftSideNav";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import NewsFeedData from "./NewsFeedData";
const queryClient = new QueryClient();

const dashboardtyles = {
  gradientBg: "bg-gradient-to-br from-[#332742] via-[#16161f] to-[#14141D]",
};
const TransactionHistory = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={`relative flex flex-row min-h-screen ${dashboardtyles.gradientBg}`}
      >
        <div className="lg:w-[20%] lg:flex hidden">
          <LeftSideNav />
        </div>

        <div className="w-full lg:w-[80%]">
          <NewsFeedData />
        </div>
      </div>
    </QueryClientProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(TransactionHistory));
