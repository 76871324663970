import React from "react";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import Header from "../Header/Header";
import HomePage from "./HomePage";

const queryClient = new QueryClient();

const dashboardstyles = {
  gradientBg: "bg-gradient-to-b from-[#16141d]  to-[#14141D] ",
};

const Dashboard = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className={` w-full ${dashboardstyles.gradientBg}`}>
        <div className="flex flex-col w-full min-h-screen ">
          <Header />
          <HomePage />
        </div>
      </div>
    </QueryClientProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
