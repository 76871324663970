import React from "react";
import Logo from "../assests/Logo.svg";
import Sphere from "../assests/Sphere.svg";
import HomeIcon from "../assests/home.svg";
import HomeIcon50 from "../assests/home-50.svg";
import UserIcon from "../assests/user.svg";
import UserIcon50 from "../assests/user-50.svg";
import WalletIcon from "../assests/wallet.svg";
import WalletIcon50 from "../assests/wallet-50.svg";
// import ExchnageIcon from "../assests/exchange.svg";
// import ExchnageIcon50 from "../assests/exchange-50.svg";
import MoneyIcon from "../assests/money.svg";
import MoneyIcon50 from "../assests/money-50.svg";
import HistoryIcon from "../assests/history.svg";
import HistoryIcon50 from "../assests/history-50.svg";
import LogoutIcon from "../assests/logout.svg";

import { Link, withRouter, useHistory } from "react-router-dom";
import { logout } from "../store/actions/AuthActions";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";

import Avatar from "../components/avatar";

const LeftSideNav = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
    history.push("/");
  };

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  // var intials;
  // var fullName = userDetails && userDetails[4];
  // intials =
  //   fullName &&
  //   fullName
  //     .split(" ")
  //     .map((name) => name[0])
  //     .join("")
  //     .toUpperCase();

  return (
    <div className="">
      <div
        className={`flex flex-col bg-[#14141d] fixed z-10 top-0 left-0 overflow-x-hidden w-[20%] rounded-tr-3xl  min-h-full `}
      >
        <div className="py-12">
          <div className="w-full flex flex-col justify-center items-center">
            <img src={Logo} alt="Logo" width={50} />
            <div className="pt-4 text-white text-xl font-semibold font-montserrat">
              Dashboard
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-2  pr-8">
          <Link
            to="/"
            className={`  w-full pl-4 flex items-center justify-between    h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px]  ${
              window.location.pathname === "/"
                ? ` text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]   to-[#818CEE33] rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                : "font-light text-[#FFFFFF]/50"
            }`}
          >
            <div className={` flex items-center w-full space-x-4`}>
              {window.location.pathname === "/" ? (
                <img src={HomeIcon} alt="Home Icon" className="w-5 h-5 " />
              ) : (
                <img src={HomeIcon50} alt="Home Icon" className="w-5 h-5 " />
              )}

              <span className="flex  text-[16px] ">Home</span>
            </div>
            {window.location.pathname === "/" ? (
              <img
                src={Sphere}
                alt="Sphere"
                className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
              />
            ) : (
              <div className="w-12"></div>
            )}
          </Link>

          <Link
            to="/user-details"
            className={`   w-full pl-4 flex items-center justify-between    h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] ${
              window.location.pathname === "/user-details"
                ? ` text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]  to-[#818CEE33] rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                : "font-light text-[#FFFFFF]/50"
            }`}
          >
            <div className={` flex items-center w-full space-x-4`}>
              {window.location.pathname === "/user-details" ? (
                <img src={UserIcon} alt="User Icon" className="w-5 h-5 " />
              ) : (
                <img src={UserIcon50} alt="User Icon" className="w-5 h-5 " />
              )}
              <span className="flex   text-[16px] ">User Details</span>
            </div>

            {window.location.pathname === "/user-details" ? (
              <img
                src={Sphere}
                alt="Sphere"
                className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
              />
            ) : (
              <div className="w-12"></div>
            )}
          </Link>

          <Link
            to="/deposit"
            className={`   w-full pl-4 flex items-center justify-between    h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] ${
              window.location.pathname === "/deposit"
                ? `text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]  to-[#818CEE33] rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                : "font-light text-[#FFFFFF]/50"
            }`}
          >
            <div className={` flex items-center w-full space-x-4`}>
              {window.location.pathname === "/deposit" ? (
                <img src={WalletIcon} alt="Wallet Icon" className="w-5 h-5 " />
              ) : (
                <img
                  src={WalletIcon50}
                  alt="Wallet Icon"
                  className="w-5 h-5 "
                />
              )}
              <span className="flex   text-[16px] ">Deposit</span>
            </div>

            {window.location.pathname === "/deposit" ? (
              <img
                src={Sphere}
                alt="Sphere"
                className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
              />
            ) : (
              <div className="w-12"></div>
            )}
          </Link>

          <Link
            to="/withdrawal"
            className={`   w-full pl-4 flex items-center justify-between   h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] ${
              window.location.pathname === "/withdrawal"
                ? `text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]  to-[#818CEE33] rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                : "font-light text-[#FFFFFF]/50"
            }`}
          >
            <div className={` flex items-center w-full space-x-2`}>
              {window.location.pathname === "/withdrawal" ? (
                <img src={MoneyIcon} alt="Money Icon" className="w-5 h-5 " />
              ) : (
                <img src={MoneyIcon50} alt="Money Icon" className="w-5 h-5 " />
              )}
              <span className="flex   text-[16px] ">Withdrawal</span>
            </div>

            {window.location.pathname === "/withdrawal" ? (
              <img
                src={Sphere}
                alt="Sphere"
                className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
              />
            ) : (
              <div className="w-12"></div>
            )}
          </Link>
          {/* 
          <Link
            to="/integrate-exchnage"
            className={`   w-full pl-4 flex items-center justify-between   h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] ${
              window.location.pathname === "/integrate-exchnage"
                ? `text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]  to-[#818CEE33] rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                : "font-light text-[#FFFFFF]/50"
            }`}
          >
            <div className={` flex items-center w-full space-x-4`}>
              {window.location.pathname === "/integrate-exchange" ? (
                <img
                  src={ExchnageIcon}
                  alt="Exchange Icon"
                  className="w-5 h-5 "
                />
              ) : (
                <img
                  src={ExchnageIcon50}
                  alt="Exchange Icon"
                  className="w-5 h-5 "
                />
              )}
              <span className="flex   text-[16px]">Exchange</span>
            </div>

            {window.location.pathname === "/integrate-exchnage" ? (
              <img
                src={Sphere}
                alt="Sphere"
                className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
              />
            ) : (
              <div className="w-12"></div>
            )}
          </Link>
*/}
          <Link
            to="/transaction-history"
            className={`  w-full pl-4 flex items-center justify-between f  h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] ${
              window.location.pathname === "/transaction-history"
                ? `text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]  to-[#818CEE33]  rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                : "font-light text-[#FFFFFF]/50"
            }`}
          >
            <div className={` flex items-center space-x-2`}>
              {window.location.pathname === "/transaction-history" ? (
                <img
                  src={HistoryIcon}
                  alt="Transaction Icon"
                  className="w-5 h-5 "
                />
              ) : (
                <img
                  src={HistoryIcon50}
                  alt="Transaction Icon"
                  className="w-5 h-5 "
                />
              )}
              <span className="flex  text-[16px] ">Transaction History</span>
            </div>

            {window.location.pathname === "/transaction-history" ? (
              <img
                src={Sphere}
                alt="Sphere"
                className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
              />
            ) : (
              <div className="w-12"></div>
            )}
          </Link>
          {userDetails && userDetails[5] === "TRUE" ? (
            <Link
              to="/admin"
              className={`   w-full pl-4 flex items-center justify-between    h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] ${
                window.location.pathname === "/admin"
                  ? ` text-[#FFFFFF]/80 font-medium bg-gradient-to-r from-[#818CEE00]  to-[#818CEE33] rounded-r-[40px] border-[1px] border-l-[0px] border-[#818CEE]/20  `
                  : "font-light text-[#FFFFFF]/50"
              }`}
            >
              <div className={` flex items-center w-full space-x-4`}>
                {window.location.pathname === "/admin" ? (
                  <img src={UserIcon} alt="User Icon" className="w-5 h-5 " />
                ) : (
                  <img src={UserIcon50} alt="User Icon" className="w-5 h-5 " />
                )}
                <span className="flex   text-[16px] ">Admin</span>
              </div>

              {window.location.pathname === "/admin" ? (
                <img
                  src={Sphere}
                  alt="Sphere"
                  className="w-[80px] lg:-mr-2 lg:mt-1 items-end animate-slideInFromLeft transition duration-500 ease-in"
                />
              ) : (
                <div className="w-12"></div>
              )}
            </Link>
          ) : null}
          <Link
            to="#!"
            className={` px-4 flex items-center justify-between text-[#FFFFFF]/50 h-[60px] transition-all duration-500 ease-in-out hover:bg-gradient-to-r hover:to-[#818CEE33] hover:from-[#818CEE00]  rounded-r-[40px] `}
            onClick={onLogout}
          >
            <div className={` flex items-center w-full space-x-4`}>
              <img src={LogoutIcon} alt="Logout Icon" className="w-5 h-5 " />

              <span className="flex  font-light hover:text-[#ffffff]/80 hover:font-medium text-[16px] ">
                Logout
              </span>
            </div>
          </Link>
        </div>

        <div className="flex  w-full space-x-4 px-4  py-4 border-t-[1px] border-gray-800">
          <Avatar />

          <div className="flex flex-col  space-y-0.5">
            <h3 className="text-left font-semibold text-white">
              {userDetails && userDetails[4]}
            </h3>
            <p className=" text-left text-xs text-[#D9D9D980]">
              {userDetails && userDetails[1]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LeftSideNav));
