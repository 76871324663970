import React from "react";
import Moment from "moment";
import PnlValue from "../Admin/PnlValue";
const style = {
  tableData: "text-[14px] text-white font-poppins py-4 px-2 min-w-[120px]",
};

const LiveTrade = ({ tradeData }) => {
  const filteredArrays =
    tradeData && tradeData.filter((innerArray) => innerArray[7] === "Approved");
  return (
    <div className="mt-4 bg-gradient-to-b from-[#818CEE]/90  via-[#FFFFFF]/20 to-[#818CE6]/10 p-[1.5px]  rounded-[12px] md:rounded-[26px]">
      <div
        className={`flex flex-col  space-y-1 md:space-y-8 px-2 py-3 md:px-3 md:py-4 bg-gradient-to-b from-[#000000]/80 from-80% via-[#29293e] via-80% to-[#29293e] to-20%   rounded-[12px] md:rounded-[26px]  p-4 text-white`}
      >
        <div
          className={`md:px-8 flex flex-row items-center leading-5 text-sm text-[#ffffff] font-normal md:text-base lg:text-xl`}
        >
          <div className="bg-[#E43D3D] w-3 h-3 md:w-4 md:h-4 rounded-full mr-2"></div>
          Live Trade
        </div>
        {!filteredArrays && !filteredArrays ? (
          <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[50vh] custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
            <table className="  text-sm w-full relative  z-0">
              <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
                <tr className="text-white ">
                  <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                    DATE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    SYMBOL
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    POSITION
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    BUY VALUE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    QUANTITY
                  </th>

                  <th className="py-6 text-right px-2 text-[14px] font-medium">
                    PNL VALUE
                  </th>
                  <th className="py-6 text-right pr-4 text-[14px] font-medium rounded-tr-lg ">
                    PNL(in%)
                  </th>
                </tr>
              </thead>
            </table>
            <span className="w-full text-white text-2xl md:text-4xl flex flex-row justify-center py-16">
              No Live Trades
            </span>
          </div>
        ) : filteredArrays && filteredArrays.length === 0 ? (
          <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)]  lg:h-full custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
            <table className="  text-sm w-full relative  z-0">
              <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
                <tr className="text-white ">
                  <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                    DATE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    SYMBOL
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    POSITION
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    BUY VALUE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    QUANTITY
                  </th>
                  <th className="py-6 text-right px-2 text-[14px] font-medium">
                    PNL VALUE
                  </th>
                  <th className="py-6 text-right pr-4 text-[14px] font-medium rounded-tr-lg ">
                    PNL(in%)
                  </th>
                </tr>
              </thead>
            </table>
            <span className="w-full text-white text-2xl md:text-4xl flex flex-row justify-center py-16">
              No Live Trades
            </span>
          </div>
        ) : (
          <div className=" mt-10 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-400px)]  lg:h-full custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in ">
            <table className="  text-sm w-full relative  z-0">
              <thead className=" text-sm text-left  border-b-[1px]   border-[#818CEE]/20    bg-gradient-to-b to-[#000000]/20   from-[#1e1e2e]  via-[#1c1c29] ">
                <tr className="text-[#ffffff]/60 ">
                  <th className="py-6 text-left pl-4 text-[14px] font-medium rounded-tl-lg ">
                    DATE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    SYMBOL
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    POSITION
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    BUY VALUE
                  </th>
                  <th className="py-6 text-left px-2 text-[14px] font-medium">
                    QUANTITY
                  </th>

                  <th className="py-6 text-right px-2 text-[14px] font-medium">
                    PNL VALUE
                  </th>
                  <th className="py-6 text-right pr-4 text-[14px] font-medium rounded-tr-lg ">
                    PNL(in%)
                  </th>
                </tr>
              </thead>

              <tbody className="bg-gradient-to-b from-[#202132] via-[#141B22]  to-[#000000]/10 ">
                {filteredArrays &&
                  filteredArrays.map((dataItem, i) => {
                    const item = dataItem[5];
                    const checkValue = Math.sign(item);
                    const pnlPercenet =
                      dataItem[6] && dataItem[6].replace("%", "");
                    const pnlPercenetValue = Math.sign(pnlPercenet);
                    return (
                      <tr
                        key={i}
                        className=" z-0 border-b border-[#818CEE]/20  "
                      >
                        <td className={`${style.tableData} pl-4`}>
                          {Moment(String(dataItem[0])).format("DD-MM-YYYY")}
                        </td>
                        <td className={style.tableData}>{dataItem[1]}</td>
                        <td className={style.tableData}>{dataItem[2]}</td>
                        <td className={style.tableData}>{dataItem[3]}</td>
                        <td className={style.tableData}>
                          {dataItem[4].replace("₹", "")}
                        </td>

                        <td
                          className={
                            checkValue === 1
                              ? `text-right text-[#18b13f] ${style.tableData}`
                              : checkValue === -1
                              ? `text-right text-[#eb323f] ${style.tableData}`
                              : `text-right ${style.tableData}`
                          }
                        >
                          <PnlValue
                            ticker={dataItem[1]}
                            buyValue={dataItem[3]}
                            quantity={dataItem[4]}
                            pnlCheck="1"
                          />
                        </td>
                        <td
                          className={
                            pnlPercenetValue === 1
                              ? `text-right text-[#18b13f] ${style.tableData} pr-4`
                              : pnlPercenetValue === -1
                              ? `text-right text-[#eb323f] ${style.tableData} pr-4`
                              : `text-right ${style.tableData} pr-4`
                          }
                        >
                          <PnlValue
                            ticker={dataItem[1]}
                            buyValue={dataItem[3]}
                            quantity={dataItem[4]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveTrade;
