import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "moment";
import { ChevronDownIcon, ChevronUpIcon, Asterisk, Info } from "lucide-react";
import { motion } from "framer-motion";

import { UseDashboarddata } from "../Utils/AllData";
import LoadingSpinnerNew from "../components/LoadingSpinner2";

const style = {
  InputBox: "rounded-lg box-border border-[1px] border-solid border-[#d3d3ce] ",

  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10 hover:text-white/85  ease-linear duration-150 cursor-pointer",
  mainDiv: "w-full flex flex-col md:items-center mt-3",
  labelText:
    "w-full text-[#D9D9D9]/70 text-[14px] font-light font-poppins z-10",
  inputDiv:
    "w-full flex flex-row items-center justify-between border border-[#ffffff]/10 mt-2 px-3 py-3 rounded-[8px]",
  inputBox: "bg-transparent text-[#ffffff]/80 font-poppins w-full",
  dropDownSelection: "text-sm cursor-pointer font-normal",
};

const QuickTransfer = (props) => {
  const { data } = UseDashboarddata();
  const dashboardData = data && data.data.values;
  const checkAmountDataBeforeReplace = dashboardData && dashboardData[0][2];

  const checkAmountData = checkAmountDataBeforeReplace;

  const [amount, setAmount] = useState("");
  const [walletAddress, setwalletAddress] = useState("");
  const [network, setnetwork] = useState("");
  const [selectCurrency, setselectCurrency] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [showButtonText, setShowButtonText] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [checkWhiteList, setCheckWhiteList] = useState(false);

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const [show, setShow] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);

  const currentDate = new Date();
  const newDate = Moment(String(currentDate)).format("MM-DD-YYYY");

  const generateRandomString = () => {
    const length = 8;
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSubmit = () => {
    const transactionId = generateRandomString();
    setShowButtonText(true);
    if (parseFloat(amount) > parseFloat(checkAmountData.replace("₮", ""))) {
      setErrorShow(true);
      setError("Entered amount is greater than balance");
      setShowButtonText(false);
    } else {
      if (!amount || !walletAddress || !network || !selectCurrency) {
        setErrorShow(true);
        setError("All Fields are required");
        setShowButtonText(false);
      } else {
        axios
          .post("https://new-dashboard.server.sypto.xyz/api/user/withdrawal", {
            username: userDetails && userDetails[0],
            email: userDetails && userDetails[1],
            id_number: userDetails && userDetails[3],
            amount: amount,
            wallet_address: walletAddress,
            network: network,
            select_currency: selectCurrency,
            date: newDate,
            status: "Pending",
            transaction_id: transactionId,
          })
          .then((res) => {
            setShowComponent(true);
            setShowMsg("Your request for withdrawal has been sent!");
            setShowButtonText(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setAmount("");
        setselectCurrency("");
        setnetwork("");
        setwalletAddress("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showComponent]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);

  useEffect(() => {
    if (checkWhiteList) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setCheckWhiteList(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [checkWhiteList]);

  const [showMessage, setShowMessage] = useState(false);

  return (
    <div>
      <div className={style.mainDiv}>
        <div className={style.labelText}>Select Currency </div>
        <div className={`relative ${style.inputDiv}`}>
          <div
            onClick={() => {
              setShow(!show);
            }}
            className={`${style.inputBox} flex justify-between`}
          >
            {selectCurrency === "BTC" ? (
              <div className={style.dropDownSelection}> Bitcoin(BTC)</div>
            ) : selectCurrency === "USDT" ? (
              <div className={style.dropDownSelection}>USDT</div>
            ) : (
              <div className={style.dropDownSelection}>Select Option</div>
            )}

            <div
              className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
            >
              {show ? (
                <ChevronUpIcon className="w-5 h-5 text-white/50" />
              ) : (
                <ChevronDownIcon className="w-5 h-5 text-white/50" />
              )}
            </div>
          </div>
          {show && (
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, type: "spring" }}
              exit={{ y: -50, opacity: 0 }}
              className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
            >
              <div
                className={`${style.selectDiv} pl-3 font-medium`}
                onClick={() => {
                  setselectCurrency("BTC");
                  setShow(!show);
                }}
              >
                Bitcoin(BTC)
              </div>
              <div
                className={`${style.selectDiv} pl-3 font-medium`}
                onClick={() => {
                  setselectCurrency("USDT");
                  setShow(!show);
                }}
              >
                USDT
              </div>
            </motion.div>
          )}
        </div>
      </div>
      <div className={style.mainDiv}>
        <div className={style.labelText}>Network </div>
        {selectCurrency === "BTC" ? (
          <div className={`relative ${style.inputDiv}`}>
            <div
              onClick={() => {
                setShowNetwork(!showNetwork);
              }}
              className={`${style.inputBox} flex justify-between`}
            >
              {network === "BTC" ? (
                <div className={style.dropDownSelection}> BTC</div>
              ) : (
                <div className={style.dropDownSelection}>Select Option</div>
              )}

              <div
                className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
              >
                {show ? (
                  <ChevronUpIcon className="w-5 h-5 text-white/50" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 text-white/50" />
                )}
              </div>
            </div>
            {showNetwork && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
                exit={{ y: -50, opacity: 0 }}
                className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
              >
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setnetwork("BTC");
                    setShowNetwork(!showNetwork);
                  }}
                >
                  Bitcoin(BTC)
                </div>
              </motion.div>
            )}
          </div>
        ) : (
          <div className={`relative ${style.inputDiv} `}>
            <div
              onClick={() => {
                setShowNetwork(!showNetwork);
              }}
              className={`${style.inputBox} flex justify-between`}
            >
              {network === "TRON20" ? (
                <div className={style.dropDownSelection}> TRON20</div>
              ) : network === "BEP2" ? (
                <div className={style.dropDownSelection}>BEP2</div>
              ) : (
                <div className={style.dropDownSelection}>Select Option</div>
              )}

              <div
                className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
              >
                {show ? (
                  <ChevronUpIcon className="w-5 h-5 text-white/50" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 text-white/50" />
                )}
              </div>
            </div>
            {showNetwork && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
                exit={{ y: -50, opacity: 0 }}
                className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
              >
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setnetwork("TRON20");
                    setShowNetwork(!showNetwork);
                  }}
                >
                  TRON20
                </div>
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setnetwork("BEP2");
                    setShowNetwork(!showNetwork);
                  }}
                >
                  BEP2
                </div>
              </motion.div>
            )}
          </div>
        )}
      </div>
      <div className={style.mainDiv}>
        <div className={`${style.labelText} flex flex-row items-center`}>
          Wallet Address (Whitelisted)
          <Asterisk color="#E43D3D" className="text-xs -mt-2" size={12} />{" "}
          <div
            onMouseOver={() => setShowMessage(true)}
            onMouseOut={() => setShowMessage(false)}
            className="ml-2 cursor-pointer"
          >
            <Info size={16} />
          </div>
        </div>

        <div className={`relative ${style.inputDiv} `}>
          <input
            type="text"
            onChange={(e) => setwalletAddress(e.target.value)}
            value={walletAddress}
            className={`${style.inputBox} z-10 placeholder:text-gray-500`}
            placeholder="Enter Wallet Address"
          />
        </div>

        {showMessage && (
          <div className="absolute bg-[#000000]/80 p-3 z-10 rounded-lg">
            <p className="text-white text-xs">
              In QuickTransfer, only whitelisted Wallet Addresses can be used
              for withdrawals. To whitelist your address, visit the Transfer Tab
              for quick and hassle-free transactions.
            </p>
          </div>
        )}
      </div>

      <div className={style.mainDiv}>
        <div className={style.labelText}>Amount </div>
        <div className={`${style.inputDiv} z-10`}>
          {/* <span className="text-[#ffffff]/80">₮</span> */}
          <input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter Amount"
            className={`${style.inputBox} z-10 placeholder:text-gray-500`}
          />
        </div>
      </div>

      {checkWhiteList === true ? (
        <div className={`mt-6 text-sm text-[#E43D3D]`}>
          Your Wallet Address is not whitelisted.
        </div>
      ) : null}
      {errorShow === true ? (
        <div className={`mt-6 text-sm text-[#E43D3D]`}>{error}</div>
      ) : null}
      {showComponent === true ? (
        <div className={`mt-6 text-sm text-[#51DB74]`}>{showMsg}</div>
      ) : null}
      {userDetails && userDetails[7] === "FALSE" ? (
        <div
          className=" bg-[#E43D3D] mt-8 py-2 px-4 text-center text-base font-semibold text-white rounded-[12px] md:rounded-[26px] cursor-pointer"
          onClick={() => {
            setCheckWhiteList(true);
          }}
        >
          {showButtonText === true ? (
            <span className="flex flex-row items-center justify-center mr-2">
              <LoadingSpinnerNew />{" "}
              <span className="ml-2">Request Sending</span>
            </span>
          ) : (
            "Withdrawal Request"
          )}
        </div>
      ) : (
        <div
          className=" bg-[#E43D3D] mt-8 py-2 px-4 text-center text-base font-semibold text-white rounded-[12px] md:rounded-[26px] cursor-pointer"
          onClick={handleSubmit}
        >
          {showButtonText === true ? (
            <span className="flex flex-row items-center justify-center mr-2">
              <LoadingSpinnerNew />{" "}
              <span className="ml-2">Request Sending</span>
            </span>
          ) : (
            "Withdrawal Request"
          )}
        </div>
      )}
    </div>
  );
};

export default QuickTransfer;
