import React, { useState, useEffect } from "react";
import axios from "axios";

const NewsFeedData = () => {
  const [newsData, setNewsData] = useState("");

  const newsFeedCallData = async () => {
    try {
      const response = await axios.get("https://new-dashboard.server.sypto.xyz/api/news-feed");
      setNewsData(response.data.data);
    } catch (error) {
      console.error("Error fetching HTML:", error);
    }
  };
  useEffect(() => {
    newsFeedCallData();
  }, []);

  return (
    <div className="relative overflow-hidden w-full pt-10 px-4  md:pt-20 md:px-12 lg:px-24 lg:pt-20 pb-8">
      <div className="absolute -top-[420px] -left-[360px]  opacity-20 blur-sm mix-blend-multiply bg-gradient-to-b from-[#000000]/10 to-[#C16EF4]/20 w-[760px] h-[760px] rounded-full"></div>
      <div className="text-3xl text-white font-semibold font-montserrat">
        News Feed
      </div>
      <div className="text-white flex flex-col  relative z-0 px-6 pb-4 mt-6  md:space-y-12 py-8 md:py-16 md:px-10 md:mt-10 rounded-[16px]  border-[1px]  border-[#818CEE]/20">
        <div dangerouslySetInnerHTML={{ __html: newsData }} />
      </div>
    </div>
  );
};

export default NewsFeedData;
