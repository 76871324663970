import React from "react";

//React Icons
import { RiMenu3Fill, RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Logo from "../assests/Sanskar_logowotext.svg";

import DropDown from "./DropDown.js";
import MobileNav from "./MobileNav.js";

const Header = (props) => {
  const [navOpen, setNavOpen] = React.useState(false);

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  return (
    <header className="">
      <nav
        className={`w-full flex flex-row items-center justify-between lg:justify-start fixed top-0 shadow-sm bg-[#14141D] backdrop-blur-md z-50 font-poppins h-[80px] border-[1px] border-[#ffffff]/10`}
      >
        <div>
          <span className="flex flex-col items-center justify-center py-3 ml-4 md:ml-16">
            <img src={Logo} alt="Logo" width={30} />

            <span className="ml-2 text-base text-[#ffffff]/80 font-semibold   px-3 py-0.5">
              Sanskar Enterprises
            </span>
          </span>
        </div>

        <div className="pl-12 lg:flex hidden flex-row items-center lg:space-x-4 font-medium text-sm text-gray-900 mr-3">
          <Link
            to="/"
            className={
              window.location.pathname === "/"
                ? "border-b-2 border-[#ffffff]/20 py-4"
                : ""
            }
          >
            <span className="mx-3 px-3  mt-2 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
              Home
            </span>
          </Link>
          <Link
            to="/trade-history"
            className={
              window.location.pathname === "/trade-history"
                ? "border-b-2 border-[#ffffff]/20 py-4"
                : ""
            }
          >
            <span className="mx-3 mt-2 px-3 py-2 text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
              Trade History
            </span>
          </Link>
          <Link
            to="/transfer"
            className={
              window.location.pathname === "/transfer"
                ? "border-b-2 border-[#ffffff]/20 py-4"
                : ""
            }
          >
            <span className="mx-3 mt-2 px-3 py-2  text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
              Transfer
            </span>
          </Link>
          {userDetails && userDetails[5] === "TRUE" ? (
            <Link
              to="/admin"
              className={
                window.location.pathname === "/admin"
                  ? "border-b-2 border-[#ffffff]/20 py-4"
                  : ""
              }
            >
              <span className="mx-3 mt-2 px-3 py-2  text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer">
                Admin
              </span>
            </Link>
          ) : null}
        </div>

        <div className="absolute right-0">
          <DropDown user={userDetails && userDetails} />
        </div>
        <div
          onClick={() => setNavOpen(!navOpen)}
          className="text-white cursor-pointer mr-4
          justify-end flex lg:hidden"
        >
          {navOpen ? (
            <RiCloseFill size={25} color="#ffffff" />
          ) : (
            <RiMenu3Fill size={25} color="#ffffff" />
          )}
        </div>
      </nav>
      {navOpen && (
        <MobileNav userDetails={userDetails} setNavOpen={setNavOpen} />
      )}
    </header>
  );
};

export default Header;
