import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

const fetchDashboard = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/user/data/dashboard`
  );
};

const fetchTickerListData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/user/data/ticker-list-data`
  );
};

const fetchChartData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/common-data/chart-data`
  );
};
const fetchLiveTradeData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/common-data/live/live-trade`
  );
};
const fetchNewsFeedData = () => {
  return axios.get(`https://new-dashboard.server.sypto.xyz/api/news-feed`);
};

const fetchEconomicCalenderData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/news-feed/economic-calender`
  );
};

const fetchAssetsData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/common-data/assets/assets-data`
  );
};

const fetchTradeHistoryStatsData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/common-data/trade-history/stats`
  );
};

const fetchWalletAddressData = () => {
  return axios.get(
    `https://new-dashboard.server.sypto.xyz/api/common-data/currency/wallet-address`
  );
};

export const UseDashboarddata = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["user-hero", heroId], fetchDashboard, {
    initialData: () => {
      const data = queryClient
        .getQueryData("user-heroes")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseTickerTistData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["allTickers", heroId], fetchTickerListData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("allTickers")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseChartTistData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["chartData", heroId], fetchChartData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("chartData")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseLiveTradeData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["liveTrade", heroId], fetchLiveTradeData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("liveTrade")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseNewsFeedData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["newsFeed", heroId], fetchNewsFeedData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("newsFeed")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
export const UseEconomicCalenderData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["economicCalender", heroId], fetchEconomicCalenderData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("economicCalender")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseAssetsData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["assetsData", heroId], fetchAssetsData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("assetsData")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseTradeHistotyStatsData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["tradeHistoryStats", heroId], fetchTradeHistoryStatsData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("tradeHistoryStats")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseWalletAddressData = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["walletAddressData", heroId], fetchWalletAddressData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("walletAddressData")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
