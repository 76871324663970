import React, { useState, useEffect } from "react";
import styles from "../Home/Dashboard.module.css";
import axios from "axios";
import Moment from "moment";

import { motion } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import LoadingSpinnerNew from "../components/LoadingSpinner2";

import { UseWalletAddressData } from "../Utils/AllData";

const style = {
  InputBox: "rounded-lg box-border border-[1px] border-solid border-[#d3d3ce] ",

  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10 hover:text-white/85  ease-linear duration-150 cursor-pointer",
  mainDiv: "flex flex-col md:items-center px-2 mt-3 md:mt-0",
  labelText:
    "w-full text-[#D9D9D9]/70 text-[14px] font-light font-poppins z-10",
  inputDiv:
    "w-full flex flex-row items-center justify-between border border-[#ffffff]/10 mt-2 px-3 py-3 rounded-[8px]",
  inputBox: "bg-transparent text-[#ffffff]/80 font-poppins w-full",
};
const DepositData = ({ getDepositedData }) => {
  const [amount, setAmount] = useState("");
  const [walletAddress, setwalletAddress] = useState("");
  const [network, setnetwork] = useState("");
  const [selectCurrency, setselectCurrency] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [showButtonText, setShowButtonText] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const currentDate = new Date();
  const newDate = Moment(String(currentDate)).format("MM-DD-YYYY");

  const generateRandomString = () => {
    const length = 8;
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSubmit = () => {
    const transactionId = generateRandomString();

    setShowButtonText(true);
    if (!amount || !walletAddress || !network || !selectCurrency) {
      setErrorShow(true);
      setError("All Fields are required");
    } else {
      axios
        .post("https://new-dashboard.server.sypto.xyz/api/user/deposit", {
          username: userDetails && userDetails[0],
          email: userDetails && userDetails[1],
          id_number: userDetails && userDetails[3],
          // amount: amount.replace("₮", ""),
          amount: amount,
          wallet_address: walletAddress,
          network: network,
          select_currency: selectCurrency,
          date: newDate,
          status: "Pending",
          transaction_id: transactionId,
        })
        .then((res) => {
          setShowComponent(true);
          setShowMsg("Your request for Deposit has been sent!");
          setShowButtonText(false);
          getDepositedData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setAmount("");
        setselectCurrency("");
        setnetwork("");
        setwalletAddress("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showComponent]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);

  const [show, setShow] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);
  const [showWalletAddress, setShowWalletAddress] = useState(false);
  const [show1, setShow1] = useState(false);

  const { data: walletAddressData } = UseWalletAddressData();
  const walletAddressList = walletAddressData && walletAddressData.data;

  return (
    <div className="w-full pt-8">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className={`${style.mainDiv} w-full md:w-[15%]`}>
          <div className={style.labelText}>Select Currency </div>
          <div className={`relative ${style.inputDiv}`}>
            <div
              onClick={() => {
                setShow(!show);
              }}
              className={`${style.inputBox} flex justify-between`}
            >
              {selectCurrency === "BTC" ? (
                <div className={styles.dropDownSelection}> Bitcoin(BTC)</div>
              ) : selectCurrency === "USDT" ? (
                <div className={styles.dropDownSelection}>USDT</div>
              ) : (
                <div className={styles.dropDownSelection}>Select Option</div>
              )}

              <div
                className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
              >
                {show ? (
                  <ChevronUpIcon className="w-5 h-5 text-white/50" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 text-white/50" />
                )}
              </div>
            </div>
            {show && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
                exit={{ y: -50, opacity: 0 }}
                className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
              >
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setselectCurrency("BTC");
                    setShow(!show);
                  }}
                >
                  Bitcoin(BTC)
                </div>
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setselectCurrency("USDT");
                    setShow(!show);
                  }}
                >
                  USDT
                </div>
              </motion.div>
            )}
          </div>
        </div>
        <div className={`${style.mainDiv} w-full md:w-[15%]`}>
          <div className={style.labelText}>Network </div>

          {selectCurrency === "BTC" ? (
            <div className={`relative ${style.inputDiv}`}>
              <div
                onClick={() => {
                  setShowNetwork(!showNetwork);
                }}
                className={`${style.inputBox} flex justify-between`}
              >
                {network === "BTC" ? (
                  <div className={styles.dropDownSelection}> BTC</div>
                ) : (
                  <div className={styles.dropDownSelection}>Select Option</div>
                )}

                <div
                  className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                >
                  {show ? (
                    <ChevronUpIcon className="w-5 h-5 text-white/50" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5 text-white/50" />
                  )}
                </div>
              </div>
              {showNetwork && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  exit={{ y: -50, opacity: 0 }}
                  className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                >
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setnetwork("BTC");
                      setShowNetwork(!showNetwork);
                    }}
                  >
                    Bitcoin(BTC)
                  </div>
                </motion.div>
              )}
            </div>
          ) : (
            <div className={`relative ${style.inputDiv}`}>
              <div
                onClick={() => {
                  setShowNetwork(!showNetwork);
                }}
                className={`${style.inputBox} flex justify-between`}
              >
                {network === "TRON20" ? (
                  <div className={styles.dropDownSelection}> TRON20</div>
                ) : network === "BEP2" ? (
                  <div className={styles.dropDownSelection}>BEP2</div>
                ) : (
                  <div className={styles.dropDownSelection}>Select Option</div>
                )}

                <div
                  className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                >
                  {show ? (
                    <ChevronUpIcon className="w-5 h-5 text-white/50" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5 text-white/50" />
                  )}
                </div>
              </div>
              {showNetwork && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  exit={{ y: -50, opacity: 0 }}
                  className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                >
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setnetwork("TRON20");
                      setShowNetwork(!showNetwork);
                    }}
                  >
                    TRON20
                  </div>
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setnetwork("BEP2");
                      setShowNetwork(!showNetwork);
                    }}
                  >
                    BEP2
                  </div>
                </motion.div>
              )}
            </div>
          )}
        </div>
        <div className={`${style.mainDiv} w-full md:w-[30%]`}>
          <div className={style.labelText}>Wallet Address :</div>
          <div
            className={`relative ${style.inputDiv} justify-between md:justify-start`}
          >
            {network === "BTC" ? (
              <>
                <div
                  onClick={() => {
                    setShowWalletAddress(!showWalletAddress);
                  }}
                  className={`${style.inputBox} flex `}
                >
                  {walletAddress ===
                  `${walletAddressList && walletAddressList[0][2]}` ? (
                    <div className={styles.dropDownSelection}>
                      {walletAddressList && walletAddressList[0][2].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][2].slice(0, 25) + "..."
                        : walletAddressList && walletAddressList[0][2]}
                    </div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showWalletAddress ? (
                      <ChevronUpIcon className="w-5 h-5 text-white/50" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 text-white/50" />
                    )}
                  </div>
                </div>
                {showWalletAddress && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] py-4 z-50`}
                  >
                    <div
                      className={`${style.selectDiv} pl-3 font-medium`}
                      onClick={() => {
                        setwalletAddress(
                          walletAddressList && walletAddressList[0][2]
                        );
                        setShowWalletAddress(!showWalletAddress);
                      }}
                    >
                      {walletAddressList && walletAddressList[0][2].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][2].slice(0, 25) + "..."
                        : walletAddressList && walletAddressList[0][2]}
                    </div>
                  </motion.div>
                )}
              </>
            ) : network === "TRON20" ? (
              <>
                <div
                  onClick={() => {
                    setShowWalletAddress(!showWalletAddress);
                  }}
                  className={`${style.inputBox} flex `}
                >
                  {walletAddress ===
                  `${walletAddressList && walletAddressList[0][0]}` ? (
                    <div className={styles.dropDownSelection}>
                      {walletAddressList && walletAddressList[0][0].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][0].slice(0, 25) + "..."
                        : walletAddressList && walletAddressList[0][0]}
                    </div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showWalletAddress ? (
                      <ChevronUpIcon className="w-5 h-5 text-white/50" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 text-white/50" />
                    )}
                  </div>
                </div>
                {showWalletAddress && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] py-4 z-50`}
                  >
                    <div
                      className={`${style.selectDiv} pl-3 font-medium`}
                      onClick={() => {
                        setwalletAddress(
                          walletAddressList && walletAddressList[0][0]
                        );
                        setShowWalletAddress(!showWalletAddress);
                      }}
                    >
                      {walletAddressList && walletAddressList[0][0].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][0].slice(0, 25) + "..."
                        : walletAddressList && walletAddressList[0][0]}
                    </div>
                  </motion.div>
                )}
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    setShowWalletAddress(!showWalletAddress);
                  }}
                  className={`${style.inputBox} flex `}
                >
                  {walletAddress ===
                  `${walletAddressList && walletAddressList[0][1]}` ? (
                    <div className={styles.dropDownSelection}>
                      {walletAddressList && walletAddressList[0][1].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][1].slice(0, 25) + "..."
                        : walletAddressList && walletAddressList[0][1]}
                    </div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showWalletAddress ? (
                      <ChevronUpIcon className="w-5 h-5 text-white/50" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 text-white/50" />
                    )}
                  </div>
                </div>
                {showWalletAddress && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] py-4 z-50 `}
                  >
                    <div
                      className={`${style.selectDiv} pl-3 font-medium`}
                      onClick={() => {
                        setwalletAddress(
                          walletAddressList && walletAddressList[0][1]
                        );
                        setShowWalletAddress(!showWalletAddress);
                      }}
                    >
                      {walletAddressList && walletAddressList[0][1].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][1].slice(0, 25) + "..."
                        : walletAddressList && walletAddressList[0][1]}
                    </div>
                  </motion.div>
                )}
              </>
            )}

            {walletAddress !== "" ? (
              <div
                className={
                  "md:mt-0 z-10 text-black md:ml-1 md:mr-1  font-bold text-sm cursor-pointer"
                }
                onClick={() => {
                  network === "BTC"
                    ? navigator.clipboard.writeText(
                        `${walletAddressList && walletAddressList[0][2]}`
                      )
                    : network === "TRON20"
                    ? navigator.clipboard.writeText(
                        `${walletAddressList && walletAddressList[0][0]}`
                      )
                    : navigator.clipboard.writeText(
                        `${walletAddressList && walletAddressList[0][1]}`
                      );
                  setShow1(true);
                  setTimeout(() => {
                    setShow1(false);
                  }, 5000);
                }}
              >
                {show1 ? (
                  <div
                    className={
                      "text-xs md:text-base text-center text-[#E43D3D] font-poppins font-medium"
                    }
                  >
                    Copied!
                  </div>
                ) : (
                  <div className={"flex items-center  space-x-1"}>
                    <span className="text-xs md:text-sm text-center text-[#E43D3D] font-poppins font-medium">
                      Copy
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div
                className={
                  "md:mt-0 z-10 text-black md:ml-1 md:mr-1  font-bold text-sm disabled"
                }
              >
                <div className={"flex items-center  space-x-1"}>
                  <span className="text-xs md:text-sm text-center text-[#E43D3D] font-poppins font-medium">
                    Copy
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`${style.mainDiv} w-full md:w-[20%]`}>
          <div className={style.labelText}>Amount :</div>
          <div className={`${style.inputDiv} z-10`}>
            {/* <span className="text-[#ffffff]/80">₮</span> */}
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter Amount"
              className={`${style.inputBox} z-10 placeholder:text-gray-500`}
            />
          </div>
        </div>

        <div className={`${style.mainDiv} w-full md:w-[20%]`}>
          <div className={style.labelText}></div>
          <div
            className={`mt-6 ml-8 w-full items-center z-10 border-none w-full`}
          >
            <button
              className="text-white text-lg font-semibold rounded-[24px] bg-[#E43D3D] py-2 px-12 md:px-6 md:py-3"
              onClick={handleSubmit}
            >
              {showButtonText === true ? (
                <span className="flex flex-row items-center justify-center">
                  <LoadingSpinnerNew />{" "}
                  <span className="ml-2">Request Sending</span>
                </span>
              ) : (
                "Deposit Request"
              )}
            </button>
          </div>
        </div>
      </div>
      {errorShow === true ? (
        <div className={`mt-6 text-sm text-[#E43D3D]`}>{error}</div>
      ) : null}
      {showComponent === true ? (
        <div className={`mt-6 text-sm text-[#51DB74]`}>{showMsg}</div>
      ) : null}
    </div>
  );
};

export default DepositData;
