import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import TickerBuySell from "./TickerBuySell";
import Header from "../Header/Header";
const queryClient = new QueryClient();

const dashboardstyles = {
  gradientBg: "bg-gradient-to-b from-[#16141d]  to-[#14141D] ",
};
const AdminLayout = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <div className={`${dashboardstyles.gradientBg}`}>
        <div className="w-full">
          <TickerBuySell />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default AdminLayout;
