import React, { useState, useEffect } from "react";
import styles from "../Home/Dashboard.module.css";
import axios from "axios";
import Moment from "moment";

import { motion } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon, ChevronsRight } from "lucide-react";
import LoadingSpinnerNew from "../components/LoadingSpinner2";
import { UseDashboarddata } from "../Utils/AllData";

import ApprovedCheck from "../assests/ArrovedTick.svg";

const style = {
  InputBox: "rounded-lg box-border border-[1px] border-solid border-[#d3d3ce] ",

  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-md mx-3 md-3 text-neutral-300  hover:bg-[#818CED]/10 hover:text-white/85  ease-linear duration-150 cursor-pointer",
  mainDiv: "flex flex-col md:items-center px-2 mt-3 md:mt-0",
  labelText:
    "w-full text-[#D9D9D9]/70 text-[14px] font-light font-poppins z-10",
  inputDiv:
    "w-full flex flex-row items-center border border-[#ffffff]/10 mt-2 px-3 py-3 rounded-[8px]",
  inputBox: "bg-transparent text-[#ffffff]/80 font-poppins w-full",
};

const WithdrawalData = ({ getWithdrawalData }) => {
  const { data } = UseDashboarddata();
  const dashboardData = data && data.data.values;
  const checkAmountDataBeforeReplace = dashboardData && dashboardData[0][2];

  const checkAmountData = checkAmountDataBeforeReplace;

  const [amount, setAmount] = useState();
  const [walletAddress, setwalletAddress] = useState("");
  const [network, setnetwork] = useState("");
  const [selectCurrency, setselectCurrency] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [showButtonText, setShowButtonText] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [checkWhiteList, setCheckWhiteList] = useState(false);
  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const currentDate = new Date();
  const newDate = Moment(String(currentDate)).format("MM-DD-YYYY");

  const generateRandomString = () => {
    const length = 8;
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSubmit = () => {
    const transactionId = generateRandomString();
    setShowButtonText(true);

    if (parseFloat(amount) > parseFloat(checkAmountData.replace("₮", ""))) {
      setErrorShow(true);
      setError("Entered amount is greater than balance");
      setShowButtonText(false);
    } else {
      if (!amount || !walletAddress || !network || !selectCurrency) {
        setErrorShow(true);
        setError("All Fields are required");
        setShowButtonText(false);
      } else {
        axios
          .post("https://new-dashboard.server.sypto.xyz/api/user/withdrawal", {
            username: userDetails && userDetails[0],
            email: userDetails && userDetails[1],
            id_number: userDetails && userDetails[3],
            amount: amount,
            wallet_address: walletAddress,
            network: network,
            select_currency: selectCurrency,
            date: newDate,
            status: "Pending",
            transaction_id: transactionId,
          })
          .then((res) => {
            setShowComponent(true);
            setShowMsg("Your request for Withdrawal has been sent!");
            setShowButtonText(false);
            getWithdrawalData();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setAmount("");
        setselectCurrency("");
        setnetwork("");
        setwalletAddress("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showComponent]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);

  useEffect(() => {
    if (checkWhiteList) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setCheckWhiteList(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [checkWhiteList]);

  const [show, setShow] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);
  const [showWhiteListBox, setShowWhiteListBox] = useState(false);

  const handleWhileList = async () => {
    setShowWhiteListBox(true);

    try {
      const response = await axios.post(
        "https://new-dashboard.server.sypto.xyz/api/user/ip/ip-whitelist",
        {
          username: userDetails && userDetails[0],
          ipWhilelist: "TRUE",
        }
      );

      if (response.status === 200) {
        const localStorageData = localStorage.getItem("userDetails");

        // Parse the JSON data
        if (localStorageData) {
          const userDetails = JSON.parse(localStorageData);

          // Check if the "response" array exists
          if (userDetails.response && Array.isArray(userDetails.response)) {
            // Update index 6 with "TRUE"
            userDetails.response[7] = "TRUE";

            // Store the updated data back in local storage
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
          } else {
            console.error(
              "Invalid format: 'response' array not found or not an array."
            );
          }
        } else {
          console.error("No data found in local storage.");
        }
        setShowWhiteListBox(false);
      }
    } catch (error) {
      console.error("Error while updating whitelist:", error);
      // Handle specific errors or display appropriate message to the user
    }
  };
  return (
    <div className="w-full pt-8">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className={`${style.mainDiv} w-full md:w-[15%]`}>
          <div className={style.labelText}>Select Currency</div>
          <div className={`relative ${style.inputDiv}`}>
            <div
              onClick={() => {
                setShow(!show);
              }}
              className={`${style.inputBox} flex justify-between`}
            >
              {selectCurrency === "BTC" ? (
                <div className={styles.dropDownSelection}> Bitcoin(BTC)</div>
              ) : selectCurrency === "USDT" ? (
                <div className={styles.dropDownSelection}>USDT</div>
              ) : (
                <div className={styles.dropDownSelection}>Select Option</div>
              )}

              <div
                className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
              >
                {show ? (
                  <ChevronUpIcon className="w-5 h-5 text-white/50" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 text-white/50" />
                )}
              </div>
            </div>
            {show && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
                exit={{ y: -50, opacity: 0 }}
                className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
              >
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setselectCurrency("BTC");
                    setShow(!show);
                  }}
                >
                  Bitcoin(BTC)
                </div>
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setselectCurrency("USDT");
                    setShow(!show);
                  }}
                >
                  USDT
                </div>
              </motion.div>
            )}
          </div>
        </div>
        <div className={`${style.mainDiv} w-full md:w-[15%]`}>
          <div className={style.labelText}>Network </div>

          {selectCurrency === "BTC" ? (
            <div className={`relative ${style.inputDiv}`}>
              <div
                onClick={() => {
                  setShowNetwork(!showNetwork);
                }}
                className={`${style.inputBox} flex justify-between`}
              >
                {network === "BTC" ? (
                  <div className={styles.dropDownSelection}> BTC</div>
                ) : (
                  <div className={styles.dropDownSelection}>Select Option</div>
                )}

                <div
                  className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                >
                  {show ? (
                    <ChevronUpIcon className="w-5 h-5 text-white/50" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5 text-white/50" />
                  )}
                </div>
              </div>
              {showNetwork && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  exit={{ y: -50, opacity: 0 }}
                  className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                >
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setnetwork("BTC");
                      setShowNetwork(!showNetwork);
                    }}
                  >
                    Bitcoin(BTC)
                  </div>
                </motion.div>
              )}
            </div>
          ) : (
            <div className={`relative ${style.inputDiv}`}>
              <div
                onClick={() => {
                  setShowNetwork(!showNetwork);
                }}
                className={`${style.inputBox} flex justify-between`}
              >
                {network === "TRON20" ? (
                  <div className={styles.dropDownSelection}> TRON20</div>
                ) : network === "BEP2" ? (
                  <div className={styles.dropDownSelection}>BEP2</div>
                ) : (
                  <div className={styles.dropDownSelection}>Select Option</div>
                )}

                <div
                  className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                >
                  {show ? (
                    <ChevronUpIcon className="w-5 h-5 text-white/50" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5 text-white/50" />
                  )}
                </div>
              </div>
              {showNetwork && (
                <motion.div
                  initial={{ y: -50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, type: "spring" }}
                  exit={{ y: -50, opacity: 0 }}
                  className={`absolute right-0 top-12 bg-[#14141d] rounded-lg flex flex-col w-[100%] pt-2 pb-2 z-50 `}
                >
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setnetwork("TRON20");
                      setShowNetwork(!showNetwork);
                    }}
                  >
                    TRON20
                  </div>
                  <div
                    className={`${style.selectDiv} pl-3 font-medium`}
                    onClick={() => {
                      setnetwork("BEP2");
                      setShowNetwork(!showNetwork);
                    }}
                  >
                    BEP2
                  </div>
                </motion.div>
              )}
            </div>
          )}
        </div>
        <div className={`${style.mainDiv} w-full md:w-[30%]`}>
          <div className={style.labelText}>Wallet Address </div>
          <div className={`relative ${style.inputDiv} `}>
            <input
              type="text"
              onChange={(e) => setwalletAddress(e.target.value)}
              value={walletAddress}
              className={`${style.inputBox} z-10 placeholder:text-gray-500`}
              placeholder="Enter Wallet Address "
            />
            {showWhiteListBox === true ? (
              <span className="flex flex-row items-center text-[#E43D3D] cursor-pointer">
                <svg
                  className="h-6 w-6 text-[#E43D3D] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : walletAddress !== "" ? (
              <span>
                {userDetails && userDetails[7] === "TRUE" ? (
                  <span className="flex flex-row items-center text-[#32BEA6] pr-4">
                    <img
                      src={ApprovedCheck}
                      alt="Approved Tick"
                      className="mr-2"
                    />
                    Whitelisted
                  </span>
                ) : (
                  <span
                    className="flex flex-row items-center text-[#E43D3D] border-b-2 border-[#E43D3D] cursor-pointer"
                    onClick={handleWhileList}
                  >
                    Whitelist <ChevronsRight className="ml-2" />
                  </span>
                )}
              </span>
            ) : (
              <span className="flex flex-row items-center text-[#ffffff]/60 border-b-2 border-[#ffffff]/40">
                Whitelist <ChevronsRight className="ml-2" />
              </span>
            )}
          </div>
        </div>

        <div className={`${style.mainDiv} w-full md:w-[20%]`}>
          <div className={style.labelText}>Amount </div>
          <div className={`${style.inputDiv} z-10`}>
            {/* <span className="text-[#ffffff]/80">₮</span> */}
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter Amount"
              className={`${style.inputBox} z-10 placeholder:text-gray-500`}
            />
          </div>
        </div>

        {userDetails && userDetails[7] === "FALSE" ? (
          <div className={`${style.mainDiv} w-full md:w-[20%]`}>
            <div className={style.labelText}></div>
            <div className={`mt-6 ml-8 w-full z-10 border-none justify-end`}>
              <button
                className="text-white text-lg font-semibold rounded-[24px] bg-[#E43D3D] px-8 py-3"
                onClick={() => {
                  setCheckWhiteList(true);
                }}
              >
                {showButtonText === true ? (
                  <span className="flex flex-row items-center justify-center">
                    <LoadingSpinnerNew />{" "}
                    <span className="ml-2">Request Sending</span>
                  </span>
                ) : (
                  "Withdrawal Request"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className={`${style.mainDiv} w-full md:w-[20%]`}>
            <div className={style.labelText}></div>
            <div className={`mt-6 ml-8 w-full z-10 border-none justify-end`}>
              <button
                className="text-white text-lg font-semibold rounded-[24px] bg-[#E43D3D] px-8 py-3"
                onClick={handleSubmit}
              >
                {showButtonText === true ? (
                  <span className="flex flex-row items-center justify-center">
                    <LoadingSpinnerNew />{" "}
                    <span className="ml-2">Request Sending</span>
                  </span>
                ) : (
                  "Withdrawal Request"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      {checkWhiteList === true ? (
        <div className={`mt-6 text-sm text-[#E43D3D]`}>
          Your Wallet Address is not whitelisted.
        </div>
      ) : null}
      {errorShow === true ? (
        <div className={`mt-6 text-sm text-[#E43D3D]`}>{error}</div>
      ) : null}
      {showComponent === true ? (
        <div className={`mt-6 text-sm text-[#51DB74]`}>{showMsg}</div>
      ) : null}
    </div>
  );
};

export default WithdrawalData;
